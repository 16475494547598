import { useState } from 'react'

export const useArray = <T>(initialList: T[]) => {
  const [list, setList] = useState(initialList)
  return {
    length: list.length,
    map: list.map.bind(list),
    rerender: () => setList(list => [...list]),
    push: (...items: T[]) => setList(list => [...list, ...items]),
    remove: (shouldRemove: (item: T) => boolean) =>
      setList(list => list.filter(item => !shouldRemove(item))),
    setState: setList,
  }
}
