import { Redirect, Route } from 'react-router-dom'
import { IonRouterOutlet, UseIonRouterResult } from '@ionic/react'
import { Tabs } from './Tabs'
import { NotMatchPage } from '../pages/NotMatchPage'
import { routes } from '../global/routes'
import { FC } from 'react'
import { TermsPage } from '../pages/TermsPage'
import { PrivacyPage } from '../pages/PrivacyPage'
import { AboutPage } from '../pages/AboutPage'
import { UploadPhotoFeedPage } from '../pages/UploadPhotoFeedPage'
import { AdminPanel } from './AdminPanel'
import { BoardGameChessRoomPage } from '../pages/BoardGameChessRoom'
import { ShoppingCartCheckoutPage } from '../pages/ShoppingCartCheckoutPage'
import { WelcomePage } from '../pages/WelcomePage'
import { EditProfilePage } from '../pages/EditProfilePage'
import { LoginPage } from '../pages/LoginPage'
import FeedbackPage from '../pages/FeedbackPage'
import { SportCenterCheckoutPage } from '../pages/SportCenterCheckoutPage'
import { FinishLevel1Page, FinishLevel2Page, FinishLevel3Page } from '../pages/FinishLevelPage'
import { RankingPage } from '../pages/RankingPage'

const pages: {
  path: string
  component: FC
  exact?: boolean
}[] = [
  { path: routes.welcome, component: WelcomePage },
  { path: routes.edit_profile, component: EditProfilePage },
  { path: routes.board_game.chess_room, component: BoardGameChessRoomPage },
  { path: routes.board_game.finish_level1, component: FinishLevel1Page },
  {
    path: routes.shop.supermarket_checkout,
    component: ShoppingCartCheckoutPage,
  },
  {
    path: routes.shop.sport_center_checkout,
    component: SportCenterCheckoutPage,
  },
  { path: routes.shop.finish_level2, component: FinishLevel2Page },
  { path: routes.photo_feed.upload, component: UploadPhotoFeedPage },
  { path: routes.photo_feed.finish_level3, component: FinishLevel3Page },
  { path: routes.ranking, component: RankingPage },
  { path: routes.feedback, component: FeedbackPage },
  { path: routes.terms, component: TermsPage },
  { path: routes.privacy, component: PrivacyPage },
  { path: routes.about, component: AboutPage },
  { path: routes.login, component: LoginPage },
]

export const Routes = () => {
  return (
    <>
      <IonRouterOutlet>
        <Route exact path="/">
          <Redirect to={routes.welcome} />
        </Route>
        <Route path="/tab">
          <Tabs />
        </Route>
        <Route path="/admin">
          <AdminPanel />
        </Route>
        {pages.map(page => (
          <Route key={page.path} {...page} />
        ))}
        <Route component={NotMatchPage} />
      </IonRouterOutlet>
    </>
  )
}

export function goBack(router: UseIonRouterResult, pathname: string) {
  if (router.canGoBack()) {
    router.goBack()
  } else {
    router.push(pathname, 'back')
  }
}
