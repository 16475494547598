import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonToolbar,
  useIonRouter,
  useIonToast,
} from '@ionic/react'
import { useObject } from '../hooks/use-object'
import { capitalize } from '@beenotung/tslib/string'
import { PageTitle } from '../components/PageTitle'
import { routes } from '../global/routes'
import { Rating } from '../components/Rating'
import { postAPI } from '../global/api'
import { PostFeedback } from 'web-server'
import { useToken } from '../hooks/use-token'
import { ErrorMessage } from '../components/ErrorMessage'

export let feedbackPage = {
  is_from_photo_feed: false,
}

let backTimer: any

const FeedbackPage: React.FC = () => {
  const [token] = useToken()
  const state = useObject({
    bug: '',
    appreciate: '',
    improve: '',
    rating: 0,
    error: '',
    has_submit: '',
  })
  const [present] = useIonToast()
  const router = useIonRouter()
  const backRoute = feedbackPage.is_from_photo_feed
    ? routes.photo_feed.home
    : routes.more
  function goBack() {
    if (backTimer) {
      clearTimeout(backTimer)
    }
    feedbackPage.is_from_photo_feed = false
    router.push(backRoute, 'back')
  }
  function submit() {
    const input = state.value
    postAPI<typeof PostFeedback>('feedback', {
      token: token || '',
      bug: input.bug,
      appreciate: input.appreciate,
      improve: input.improve,
      rating: input.rating,
    }).then(output => {
      state.set('error', output.error)
      if (!output.error) {
        state.set('has_submit', 'yes')
        present('Submitted feedback', 3000)
        backTimer = setTimeout(() => {
          goBack()
        }, 3000)
      }
    })
  }
  function renderItem(
    field: keyof typeof state['value'],
    title: string = capitalize(field),
    body = <IonInput type={'text'} value={state.value[field]}></IonInput>,
  ) {
    return (
      <IonItem>
        <IonLabel position="floating">{title}</IonLabel>
        {body}
      </IonItem>
    )
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton defaultHref={routes.more} />
          </IonButtons>
          <PageTitle>Feedback</PageTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonList className="ion-no-padding">
          {renderItem('bug', 'Found any bug? 🐞')}
          {renderItem('appreciate', 'Show your appreciation 💖')}
          {renderItem('improve', 'What can we improve? 💪')}
          <IonItem style={{ display: 'block' }}>
            <IonLabel>Rating</IonLabel>
            <Rating
              value={state.value.rating}
              onChange={rating => state.set('rating', rating)}
            />
          </IonItem>
          {state.value.has_submit ? (
            <IonButton
              className="ion-margin-top"
              expand="block"
              onClick={goBack}
            >
              Go Back
            </IonButton>
          ) : (
            <IonButton
              className="ion-margin-top"
              expand="block"
              onClick={submit}
            >
              Submit
            </IonButton>
          )}
          <ErrorMessage error={state.value.error} />
        </IonList>
      </IonContent>
    </IonPage>
  )
}

export default FeedbackPage
