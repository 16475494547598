import {
  IonAccordion,
  IonAccordionGroup,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonItemGroup,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonThumbnail,
  IonToggle,
  IonToolbar,
  useIonAlert,
  useIonRouter,
} from '@ionic/react'
import { addOutline, star } from 'ionicons/icons'
import { PageTitle } from '../components/PageTitle'
import { routes } from '../global/routes'
import styles from './UploadPhotoFeedPage.module.scss'
import { GetMenuOptions, PostFoodMenu } from 'web-server'
import { useGet } from '../hooks/use-get'
import { useObject } from '../hooks/use-object'
import { selectImage, fileToBase64String } from '@beenotung/tslib/file'
import { useArray } from '../hooks/use-array'
import { postAPI, uploadImages } from '../global/api'
import { splitTags } from '@beenotung/tslib/tag'
import { ErrorMessage } from '../components/ErrorMessage'
import { wrapToken } from '../components/wrap-token'
import useStorageState from 'react-use-storage-state'
import { config } from '../config'

let nextId = 1

const MaxImageCount = 5

type Image = {
  id: number
  file: File
  previewUrl: string
}

export const UploadPhotoFeedPage = wrapToken(({ token }) => {
  const state = useObject({
    desc: '',
    meal_id: 0,
    is_anonymous: false,
    tags: '',
    other_feature: '',
    upload_error: '',
    uploaded_id: 0,
  })

  const selectedFeatures = useObject<Record<number, boolean>>({})
  const router = useIonRouter()

  const options = useGet<typeof GetMenuOptions>('menu-options', {})

  const images = useArray<Image>([])

  const alert = useIonAlert()

  const [have_seen_level_3_finish, set_have_seen_level_3_finish] =
    useStorageState('have_seen_level_3_finish', false)

  const removeImage = (image: Image) => {
    alert[0]({
      message: `Confirm to remove ${image.file.name} ?`,
      buttons: [
        { text: 'Keep Photo', role: 'cancel' },
        {
          text: 'Remove Photo',
          handler: () => {
            images.remove(item => item.id == image.id)
          },
        },
      ],
    })
  }

  async function addPhotos() {
    const files = await selectImage({ multiple: true })
    for (let file of files) {
      images.push({
        id: nextId,
        file,
        previewUrl: await fileToBase64String(file),
      })
      nextId++
    }
    images.setState(list => list.slice(-MaxImageCount))
  }

  async function upload() {
    if (images.length == 0) {
      state.set('upload_error', 'Please select photos')
      return
    }
    if (!state.value.meal_id) {
      state.set('upload_error', 'Please select meal type')
      return
    }
    let feature_list = Object.keys(selectedFeatures.value)
      .map(key => +key)
      .filter(key => selectedFeatures.value[key])
    if (feature_list.length === 0) {
      state.set('upload_error', 'Please select healthy features')
      return
    }

    let photo_list = await uploadImages(
      images.map(image => image.file).slice(-5),
    )
    let date_start = new Date()
    date_start.setHours(0, 0, 0, 0)

    postAPI<typeof PostFoodMenu>('food-menu', {
      token,
      desc: state.value.desc,
      meal_id: state.value.meal_id,
      feature_list,
      tag_list: splitTags(state.value.tags),
      photo_list,
      other_feature: state.value.other_feature,
      is_anonymous: state.value.is_anonymous,
      date_start: date_start.toISOString(),
    }).then(output => {
      state.set('upload_error', output.error)
      state.set('uploaded_id', output.menu_id)
      if (
        output.is_finish_menu &&
        (!have_seen_level_3_finish || config.showFinishLevelEveryTime)
      ) {
        set_have_seen_level_3_finish(true)
        router.push(routes.photo_feed.finish_level3)
      }
    })
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton defaultHref={routes.photo_feed.home} />
          </IonButtons>
          <PageTitle>Upload DM Menu</PageTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        {images.map(image => (
          <IonCard
            key={image.id}
            className={
              styles.addPhoto +
              ' d-flex ion-align-items-center ion-justify-content-center'
            }
            onClick={() => removeImage(image)}
          >
            <img src={image.previewUrl}></img>
          </IonCard>
        ))}
        <IonCard
          className={
            styles.addPhoto +
            ' d-flex ion-align-items-center ion-justify-content-center'
          }
          onClick={addPhotos}
          hidden={images.length >= MaxImageCount}
        >
          <IonIcon
            className={styles.addIcon}
            color="primary"
            icon={addOutline}
          />
        </IonCard>
        <IonList>
          {options.render({
            name: 'DM Menu Options',
            render: data => (
              <>
                <IonItem>
                  <IonLabel>Meal</IonLabel>
                  <IonSelect
                    value={state.value.meal_id}
                    placeholder="Select One"
                    onIonChange={e => {
                      state.set('meal_id', e.detail.value)
                    }}
                  >
                    {data.meal_list.map(meal => (
                      <IonSelectOption value={meal.id} key={meal.id}>
                        {meal.meal}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>

                <IonListHeader>
                  <IonLabel className="ion-text-wrap">
                    My Food Choice is Healthy because:
                  </IonLabel>
                </IonListHeader>
                <IonItemGroup>
                  <IonAccordionGroup>
                    {data.category_list.map(({ category, feature_list }) => (
                      <IonAccordion key={category.id}>
                        <IonItem slot="header">
                          <IonLabel className="ion-text-wrap">
                            {category.category}
                          </IonLabel>
                          <IonThumbnail
                            slot="end"
                            style={{ width: '132px', height: '44px' }}
                            className={styles.categoryImage}
                          >
                            <img
                              src={category.image_url}
                              alt={'icon of ' + category.category}
                            />
                          </IonThumbnail>
                        </IonItem>
                        <IonList slot="content">
                          {feature_list.map(feature => (
                            <IonItem key={feature.id}>
                              <IonCheckbox
                                slot="start"
                                value={selectedFeatures.value[feature.id]}
                                onIonChange={e => {
                                  selectedFeatures.set(
                                    feature.id,
                                    e.detail.checked,
                                  )
                                }}
                              />
                              <IonLabel
                                className="ion-text-wrap"
                                color={
                                  selectedFeatures.value[feature.id]
                                    ? 'primary'
                                    : ''
                                }
                              >
                                {feature.feature}
                              </IonLabel>
                            </IonItem>
                          ))}
                        </IonList>
                      </IonAccordion>
                    ))}
                  </IonAccordionGroup>
                </IonItemGroup>
              </>
            ),
          })}

          <IonItem>
            <IonLabel position="stacked">
              Description (Max 200 letters)
            </IonLabel>
            <IonInput
              placeholder="Share your thoughts"
              value={state.value.desc}
              onIonChange={e =>
                state.set('desc', (e.detail.value || '').slice(0, 200))
              }
            />
          </IonItem>

          <IonItem>
            <IonLabel position="stacked">Tags (Optional)</IonLabel>
            <IonTextarea
              placeholder="Separated by comma or spaces (e.g. Tasty, Beauty, Keep Fit)"
              value={state.value.tags}
              onIonChange={e =>
                state.set('tags', (e.detail.value || '').slice(0, 200))
              }
            />
          </IonItem>

          <IonItem>
            <IonLabel>Anonymous</IonLabel>
            <IonToggle />
          </IonItem>

          <ErrorMessage error={state.value.upload_error} />

          {!state.value.uploaded_id ? (
            <IonButton expand="block" onClick={upload} className="mt-3">
              Submit
            </IonButton>
          ) : (
            <>
              <div className="ion-text-center">
                <p>Uploaded as menu # {state.value.uploaded_id}</p>
                <p>
                  Let's give rating to others! &nbsp;
                  <IonIcon icon={star} color="primary" />
                </p>
                <IonButton
                  routerLink={routes.photo_feed.home}
                  routerDirection="back"
                  ref={e =>
                    e?.scrollIntoView({ behavior: 'smooth', inline: 'center' })
                  }
                >
                  Go back
                </IonButton>
              </div>
            </>
          )}
        </IonList>
        <div style={{ height: '2rem' }}></div>
      </IonContent>
    </IonPage>
  )
})
