import { IonPage, IonContent, IonButton, IonIcon } from '@ionic/react'
import { routes } from '../global/routes'
import { assets } from '../assets/index'
import { gameController, help } from 'ionicons/icons'
import { useCallback, useState } from 'react';
import { useTimeout } from '../hooks/use-timeout'

const win: {
  buildId: string
} = window as any

export const WelcomePage = () => {
  const [buildId, setBuildId] = useState(win.buildId)
  const checkBuildId = useCallback(() => {
    if (buildId !== win.buildId) {
      setBuildId(win.buildId)
    }
  }, [buildId])
  useTimeout(checkBuildId, 0)
  return (
    <IonPage>
      <IonContent>
        <img style={{ marginTop: '3.5rem' }} src={assets.bannerH}></img>

        <div className="ion-padding">
          <div>
            <IonButton
              expand="block"
              routerLink={routes.home}
              routerDirection="root"
            >
              <IonIcon icon={gameController}></IonIcon> Start
            </IonButton>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>{buildId ? <>build id: {buildId}</> : null}</div>
            <IonButton color="tertiary" routerLink={routes.about}>
              <IonIcon icon={help}></IonIcon> Help
            </IonButton>
          </div>
        </div>
      </IonContent>
    </IonPage>
  )
}
