export const routes = {
  welcome: '/welcome',
  home: '',

  /* top-level tabs */
  my_profile: '/tab/my-profile',
  board_game: {
    waiting_room: '/tab/board-game/waiting-room',
    chess_room: '/board-game/chess-room',
    finish_level1: '/board-game/finish',
  },
  shop: {
    home: '/tab/shop',
    supermarket_checkout: '/shop/supermarket/checkout',
    sport_center_checkout: '/shop/sport-center/checkout',
    finish_level2: '/shop/finish',
  },
  photo_feed: {
    home: '/tab/photo-feed',
    upload: '/photo-feed/upload',
    finish_level3: '/photo-feed/finish',
  },
  more: '/tab/more',

  /* from my_profile */
  edit_profile: '/profile/edit',

  /* from more tab */
  admin: {
    home: '/admin',
    dm_feature: '/admin/dm-feature',
  },
  ranking: '/ranking',
  feedback: '/feedback',
  terms: '/terms',
  privacy: '/privacy',
  about: '/about',
  login: '/login',
}

routes.home = routes.my_profile
