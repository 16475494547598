import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
  IonBackButton,
} from '@ionic/react'
import { routes } from '../global/routes'

export const TermsPage = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton defaultHref={routes.more}/>
          </IonButtons>
          <IonTitle>Terms of Use</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <p>
          Please read these Terms of Use carefully. By installing, accessing or
          using this application (“Application”), you are consenting to these
          Terms of Use. If you do not accept these Terms of Use, do not access
          this Application.
        </p>
        <h2>1. Application Contents</h2>
        <p>
          This Application of The Hong Kong Polytechnic University (“PolyU”,
          “we”, “us”, “our’) is for general reference. We may, at our absolute
          discretion and at any time, without prior notice to you, add to, amend
          or remove material from this Application, or alter the presentation,
          substance, or functionality of this Application.
        </p>
        <h2>2. Restrictions on use</h2>
        <p>
          Subject to your compliance with the Terms of Use, we grant you the
          permission and a personal and non-transferable license to install and
          use the Application on a mobile device which you personally own or
          control. You shall ensure that your mobile device meets all the
          requisite technical specifications to enable you to install and use
          the Application.
        </p>
        <p>
          The Application is provided for your personal and lawful use only and
          you shall not make use of the Application for commercial or malicious
          purpose. In particular, you are not allowed to resell, lend or charge
          others for installation, use or access to the Application. You shall
          not, or authorize any third party to, decompile, reverse-engineer,
          convert, translate, alter, add to, delete, modify, enhance, customize
          all or any part of the Application.
        </p>
        <p>
          We reserve the right at all times: e to temporarily suspend the
          Application without prior notice where we consider it reasonably
          necessary for the operation of the Application; to reject the
          installation of the Application by any person; e to suspend, deny or
          terminate your access to or use of the Application or any part thereof
          immediately and without prior notice if we are in our sole opinion
          that you are in breach of the Terms of Use and we shall not be liable
          for any loss or damage you may incur or suffer as a result of such
          suspension, restriction or termination.
        </p>
        <h2>3. Links from this Application</h2>
        <p>
          The links on this Application may take you to third-party websites or
          services that are not owned or controlled by us. You acknowledge and
          agree that we have no control over, and assume no responsibility for
          the content, privacy policies, or practices of any third-party
          websites or services. Links to other websites do not constitute an
          endorsement by us of such websites or the information, products,
          advertising, or other materials available on those websites.
        </p>
        <h2>4. Intellectual Property Rights</h2>
        <p>
          All intellectual property rights subsisting in respect of this
          Application belong to us or have been lawfully licensed to us for use
          on this Application. All rights under applicable laws are hereby
          reserved. Except with our express written permission, you are not
          allowed to upload, post, publish, reproduce, transmit or distribute in
          any way any component of this Application itself or create derivative
          works with respect thereto, as this Application is copyrighted under
          applicable laws. You may only download such part of this Application
          as is expressly permitted to be downloaded for the purposes specified.
          You have no rights in or to the contents and you will not use them
          except as permitted under these Terms of Use.
        </p>
        <h2>5. Limited Liability and Responsibility</h2>
        <p>
          This Application is provided by us on an “as is” and “as available”
          basis. All information is for your general reference only. We do not
          accept any responsibility whatsoever in respect of such information.
          We do not guarantee or assume any responsibility that: « this
          Application is available or will be uninterrupted or error-free, or
          that defects will be corrected; e the information on this Application
          is accurate, adequate, current or reliable, or may be used for any
          purpose other than for general reference; ethe information on this
          Application is free of defect, error, omission, virus, bugs or
          anything which may change, erase, add to or damage your software, data
          or equipment; or the messages sent through the internet will be free
          from interception, corruption or loss.
        </p>
        <p>
          We make no representation that the information on this Application is
          appropriate or available for use in any other jurisdictions. Those who
          access this Application from other locations do so at their discretion
          and are solely responsible for compliance with their laws and rules.
          We disclaim all warranties and representations of any kind, whether
          express or implied, including but not limited to warranties on
          merchantability, fitness for a particular purpose or non-infringement.
          We will not be liable for any loss or damage however caused (save and
          except provided by law), including but not limited to special or
          consequential damages such as loss of business or profit, arising out
          of any use, or inability to use, omission in the Application, even if
          we have been advised of the possibility of such damages.
        </p>
        <h2>6. Privacy Policy</h2>
        <p>
          For information about our privacy policies and practices, please refer
          to our Privacy Policy Statement. Where personal data is collected, you
          should also have reference to the relevant Personal Information
          Collection Statement in the form through which your personal data is
          collected.
        </p>
        <h2>7. Push notifications</h2>
        <p>
          After you download the Application, you have the option to opt in to
          receive push notifications from PolyU on your mobile device. These
          push notifications may include but not limited to PolyU press
          releases, newsletters, publications, videos and news coverage, and
          special announcements. You may, at any time after the download of this
          Application, opt out of receiving push notifications by adjusting the
          settings on your mobile device.
        </p>
        <h2>8. Governing Law and Jurisdiction</h2>
        <p>
          These Terms of Use shall be governed by the law of the Hong Kong
          Special Administrative Region of the People's Republic of China (“Hong
          Kong”). You agree to submit to the non-exclusive jurisdiction of the
          Hong Kong courts.
        </p>
        <h2>9. No waiver</h2>
        <p>
          Any failure or delay to exercise all or any part of any right, power
          or remedy shall not operate as a waiver of that right, power or remedy
          or preclude its exercise at any subsequent time or on any subsequent
          occasion.
        </p>
        <h2>10. Severability</h2>
        <p>
          If any provision of these Terms of Use is held invalid or
          unenforceable, such provision will be deemed deleted from the Terms of
          Use without invalidating the remaining provisions of the Terms of Use
          or affecting validity or enforceability of such remaining provisions.
        </p>
        <h2>11. Entire agreement</h2>
        <p>
          These Terms of Use constitute our entire agreement with you regarding
          your use of the Application and supersedes any prior agreement
          (whether oral or written between us and you) or understanding
          concerning the use of the Application.
        </p>
        <h2>12. General Matters</h2>
        <p>
          These Terms of Use are subject to change. Any changes will be posted
          on this page. Your continued use of our Application after the posting
          of such changes indicates your acceptance to the changes.
        </p>
      </IonContent>
    </IonPage>
  )
}
