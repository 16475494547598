export function formatTimestamp(time: string | number) {
  return Intl.DateTimeFormat('en-HK', {
    dateStyle: 'medium',
    timeStyle: 'short',
  }).format(fromTimestamp(time))
}

/**
 * @input format '2022-01-04 05:01:16'
 * @output format '2022-01-04T05:01:16Z'
 */
export function fromTimestamp(time: string | number) {
  if (typeof time == 'string' && time.includes(' ')) {
    time = time.replace(' ', 'T') + 'Z'
  }
  return new Date(time)
}

export function toPlayerNickname(player: { id: number; nickname: string }) {
  return player.nickname || `player ${player.id}`
}
