import { compressMobilePhoto, dataURItoBlob } from '@beenotung/tslib/image'
import { KB } from '@beenotung/tslib/size'
import { simpleHash } from './hash'

export let SERVER_ORIGIN = window.location.origin.includes('localhost:3000')
  ? 'http://localhost:8100'
  : window.location.origin
// : window.location.origin == 'https://dmmasterapp.com'
// ? 'https://dmmasterapp.com'
// : 'https://dmmaster-sn.polyu.edu.hk'

export let SAMPLE_PHOTO = '/assets/image/placeholder.webp'

export let Anonymous = 'Anonymous'

export function toImageUrl(filename: string) {
  if (!filename) {
    return SAMPLE_PHOTO
  }
  if (filename.startsWith('/assets')) {
    return filename
  }
  if (filename.startsWith('/static')) {
    return filename
  }
  if (filename.startsWith('http')) {
    return filename
  }
  return SERVER_ORIGIN + '/file/' + filename
}

export function toAvatarUrl(filename?: string, id?: number | string) {
  if (filename?.startsWith('http')) {
    return filename
  }
  return filename
    ? toImageUrl(filename)
    : id
    ? avatarPlaceHolder(id)
    : SAMPLE_PHOTO
}

export function avatarPlaceHolder(id: number | string) {
  let hash = simpleHash(String(id))
  return `https://www.gravatar.com/avatar/${hash}?d=monsterid&f=y`
  // return 'https://joeschmoe.io/api/v1/' + id //  this service is down
}

export function postAPI<
  A extends { method: 'post'; name: string; input: object; output: object },
>(name: A['name'], input: A['input']) {
  return callAPI<A>('post', name, input)
}

export async function callAPI<
  A extends {
    method: 'get' | 'post'
    name: string
    input: object
    output: object
  },
>(
  method: A['method'],
  name: A['name'],
  input: A['input'],
): Promise<A['output']> {
  try {
    console.debug('call', method, name)
    let url = `${SERVER_ORIGIN}/api/${name}`
    let res: Response
    switch (method) {
      case 'get':
        res = await fetch(url + '?' + toSearchParams(input), {
          method,
          headers: {
            Accept: 'application/json',
          },
        })
        break
      case 'post':
        res = await fetch(url, {
          method,
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          body: JSON.stringify(input),
        })
        break
      default: {
        let _method: never = method
        throw new Error('Method Not Supported: ' + _method)
      }
    }
    let output = await res.json()
    console.debug('result of', method, name, output)
    // if (output?.error?.includes('please login again')) {
    //   console.log('reset token...')
    //   localStorage.removeItem('token')
    //   localStorage.removeItem('token:default')
    // }
    return output
  } catch (error) {
    console.error(`Failed to ${method} ${name}:`, error)
    return { error: `Network Error: Failed to ${method} ${name}: ${error}` }
  }
}

function toSearchParams(input: object) {
  let params = new URLSearchParams()
  Object.entries(input).forEach(([key, value]) => params.set(key, value))
  return params
}

export async function uploadImages(files: File[]): Promise<string[]> {
  const formData = new FormData()
  for (let file of files) {
    file = await compressImageFile(file)
    formData.append('image', file)
  }
  const res = await fetch(SERVER_ORIGIN + '/file/multiple', {
    method: 'POST',
    body: formData,
  })
  const json = await res.json()
  return json.filename_list
}

export async function uploadImage(file: File): Promise<string> {
  const formData = new FormData()
  file = await compressImageFile(file)
  formData.append('image', file)
  const res = await fetch(SERVER_ORIGIN + '/file/single', {
    method: 'POST',
    body: formData,
  })
  const json = await res.json()
  return json.filename
}

async function compressImageFile(file: File): Promise<File> {
  let dataUrl = await compressMobilePhoto({
    image: file,
    maximumSize: 180 * KB,
  })
  let blob = dataURItoBlob(dataUrl)
  return new File([blob], file.name, {
    lastModified: file.lastModified,
    type: blob.type,
  })
}
