export const AboutLevel3 = () => {
  return (
    <>
      <h2>Level 3: Featured DM Diet</h2>
      <i>Hashtags your Featured Food Diet</i>
      <p>
        Set the trend of healthy food choice for diabetes self-management and
        transform your community.
      </p>
      <p>
        Share your featured food menu and see if they are compatible with the
        diabetes food guidelines.
      </p>
    </>
  )
}
