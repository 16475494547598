import { assets } from '../assets'
import styles from './Tips.module.scss'

export function Tips(props: { text: string | JSX.Element }) {
  return (
    <div className={styles.hintContainer}>
      <img src={assets.tips} className={styles.tipsIcon} />
      <div>{props.text}</div>
    </div>
  )
}
