import { IonApp, setupIonicReact } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import { Routes } from './components/Routes'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Theme variables */
import './theme/variables.css'
import './theme/styles.css'
import './App.css'

document.body.classList.add('has-script')

setupIonicReact()

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <Routes />
    </IonReactRouter>
  </IonApp>
)

export default App

function checkPlatform() {
  if (navigator.userAgent.includes('iPhone')) {
    document.body.classList.remove('desktop-wrapper')
  } else {
    document.body.classList.add('desktop-wrapper')
  }
}
window.addEventListener('resize', checkPlatform)
requestAnimationFrame(checkPlatform)
