import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonContent,
  IonButtons,
  IonBackButton,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  useIonToast,
  useIonRouter,
  IonCheckbox,
} from '@ionic/react'
import { PageTitle } from '../components/PageTitle'
import { routes } from '../global/routes'
import { useObject } from '../hooks/use-object'
import styles from './LoginPage.module.scss'
import { is_email } from '@beenotung/tslib/validate'
import { useEffect } from 'react'
import { postAPI } from '../global/api'
import type {
  PostEmailCodeVerify,
  PostEmailSignUp,
  PostEmailVerify,
} from 'web-server'
import { MINUTE, SECOND } from '@beenotung/tslib/time'
import { useToken } from '../hooks/use-token'
import { ErrorMessage } from '../components/ErrorMessage'
import { assets } from '../assets/index'
import { isPlatform } from '@ionic/core'

export const LoginPage = () => {
  /* hooks */
  const router = useIonRouter()
  const params = new URLSearchParams(router.routeInfo.search)
  const back = params.get('back')

  /* state */
  const state = useObject({
    email: '',
    email_error: '',
    has_sent_email: false,
    verify_code: '',
    verify_code_error: '',
    expire_time: Date.now(),
    has_sent_code: false,
    clock: Date.now(),
    last_sent_email: '',
    new_email: '',
    has_view_terms: false,
    has_agree: false,
  })
  const [token, setToken] = useToken()

  /* variant */
  const { patch: patchState } = state
  const { email, last_sent_email, has_sent_email } = state.value
  const clock = Date.now()
  const expire_time = state.value.expire_time || 0
  const is_email_valid = is_email(state.value.email)
  const is_code_valid = state.value.verify_code.length === 6
  const ms_before_expire = expire_time - clock
  const has_expired = clock >= expire_time
  const can_verify_email =
    is_email_valid &&
    (!has_sent_email ||
      has_expired ||
      state.value.verify_code_error ||
      email !== last_sent_email)
  const can_verify_code =
    is_email_valid &&
    has_sent_email &&
    (!state.value.email_error || state.value.email_error.startsWith('dev:'))

  const is_new_email = state.value.email_error === 'The email is not registered'
  const is_same_new_email = state.value.new_email === state.value.email

  /* side effect */
  useEffect(() => {
    if (clock < expire_time) {
      let timer = setTimeout(() => {
        patchState({ clock: Date.now() })
      }, 1000)
      return () => clearTimeout(timer)
    }
  }, [clock, expire_time, patchState])

  /* method */
  const ionToast = useIonToast()
  const onOutput = (
    email: string,
    output: { error: string; expire_time: number },
  ) => {
    state.set('last_sent_email', email)
    state.set('email_error', output.error)
    state.set('expire_time', output.expire_time)
    if (output.error === 'The email is not registered') {
      state.set('new_email', email)
    } else {
      state.set('new_email', '')
    }
    if (!output.error || output.error.startsWith('dev:')) {
      state.set('has_sent_email', true)
    }
  }
  const signUp = () => {
    state.set('email_error', '')
    postAPI<typeof PostEmailSignUp>('email-sign-up', { email }).then(output => {
      onOutput(email, output)
    })
  }
  const verifyEmail = () => {
    if (!is_email_valid) {
      ionToast[0]({ message: 'Invalid email', duration: 3000 })
      return
    }
    if (has_sent_email && !has_expired && email === last_sent_email) {
      ionToast[0]({ message: 'Please try again later', duration: 3000 })
      return
    }
    state.set('has_sent_email', false)
    state.set('verify_code', '')
    state.set('verify_code_error', '')
    postAPI<typeof PostEmailVerify>('email-verify', { email }).then(output => {
      onOutput(email, output)
    })
  }
  const verifyCode = () => {
    postAPI<typeof PostEmailCodeVerify>('email-code-verify', {
      verify_code: state.value.verify_code,
    }).then(data => {
      state.set('verify_code_error', data.error)
      if (data.token) {
        setToken(data.token)
        if (back === 'more') {
          router.push(routes.more, 'root')
        }
      }
    })
  }

  /* view */
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start" hidden={back !== 'more'}>
            <IonBackButton defaultHref={routes.more}></IonBackButton>
          </IonButtons>
          <PageTitle>Login</PageTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <div className={styles.loginBannerContainer}>
          <img
            src={assets.bannerW}
            className={styles.loginBanner}
            alt="banner of this app: DM Master"
          />
        </div>
        <IonList>
          <IonItem
            lines="none"
            routerLink={routes.terms}
            onClick={() => state.patch({ has_view_terms: true })}
          >
            <IonLabel>Terms of Use</IonLabel>
            <IonButton size="small" slot="end" hidden={isPlatform('ios')}>
              view
            </IonButton>
          </IonItem>
          <IonItem disabled={!state.value.has_view_terms}>
            <IonLabel>I agree</IonLabel>
            <IonCheckbox
              slot="end"
              checked={state.value.has_agree}
              onIonChange={e => state.patch({ has_agree: e.detail.checked })}
            ></IonCheckbox>
          </IonItem>
          <IonItem disabled={!state.value.has_agree}>
            <IonLabel position="stacked" color="primary">
              Email
            </IonLabel>
            <IonInput
              type="email"
              value={state.value.email}
              onIonChange={e =>
                state.set('email', (e.detail.value || '').trim())
              }
            ></IonInput>
          </IonItem>
          <ErrorMessage error={state.value.email_error} />
          {is_new_email && is_same_new_email ? (
            <IonButton expand="block" onClick={signUp} color="primary">
              Tap again to sign up new account
            </IonButton>
          ) : is_new_email && !is_same_new_email ? (
            <IonButton expand="block" onClick={verifyEmail}>
              Send Verification Code
            </IonButton>
          ) : (
            <IonButton
              expand="block"
              disabled={!can_verify_email}
              onClick={verifyEmail}
            >
              {last_sent_email &&
              email !== last_sent_email &&
              is_email_valid ? (
                <>Resend Verification Code</>
              ) : ms_before_expire > MINUTE ? (
                <>
                  The token will expire in{' '}
                  {(ms_before_expire / MINUTE).toFixed(1).replace('.0', '')}{' '}
                  minutes
                </>
              ) : ms_before_expire > SECOND ? (
                <>
                  The token will expire in{' '}
                  {Math.floor(ms_before_expire / SECOND)} seconds
                </>
              ) : ms_before_expire > 0 ? (
                <>The token has expired</>
              ) : state.value.has_sent_email ? (
                <>Resend Verification Code</>
              ) : (
                <>Send Verification Code</>
              )}
            </IonButton>
          )}
          {ms_before_expire > 0 ? (
            <>
              <p>A 6-digit verification code is sent to your email address.</p>
              <p>
                If you cannot find the email in your inbox, you may check the
                spam folder.
              </p>
            </>
          ) : null}
          <IonItem>
            <IonLabel position="stacked" color="primary">
              Verification Code
            </IonLabel>
            <IonInput
              type="number"
              disabled={!can_verify_code}
              value={state.value.verify_code}
              onIonChange={e =>
                state.set('verify_code', (e.detail.value || '').trim())
              }
            ></IonInput>
          </IonItem>
        </IonList>
        <IonButton
          expand="block"
          disabled={!is_code_valid}
          onClick={verifyCode}
        >
          Login
        </IonButton>
        <ErrorMessage error={state.value.verify_code_error} />
        {/* to avoid the scrollbar to be toggled alternatively when showing cool down timer */}
        <div style={{ height: '10vh' }}></div>
      </IonContent>
    </IonPage>
  )
}
