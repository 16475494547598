import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonContent,
  IonButtons,
  IonBackButton,
} from '@ionic/react'
import { AboutLevel1 } from '../components/AboutLevel1'
import { AboutLevel2 } from '../components/AboutLevel2'
import { AboutLevel3 } from '../components/AboutLevel3'
import { PageTitle } from '../components/PageTitle'
import { routes } from '../global/routes'

export const AboutPage = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton defaultHref={routes.more} />
          </IonButtons>
          <PageTitle>Game Instruction</PageTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <h1>Diabetes Master e-GAME</h1>
        <h2>Introduction</h2>
        <p>
          This is an interactive education tool to support you to learn more
          about Type 2 diabetes from a health professional perspective. The game
          has three levels: diagnosis of diabetes, its signs and symptoms, and
          common complications. You will also explore the goals and strategies
          of diabetes management, including healthy lifestyles and medications.
        </p>
        <p>
          In this e-GAME environment, you will earn your virtual coins to enjoy
          digital shopping and exercises. The scoreboard shows your number of
          coins (your scores) and your ranking. Set a new score record and get
          your name on top of the ranking! You can also see the best rated food
          menu of the day in the ranking list. Start now and learn with great
          fun!
        </p>
        <AboutLevel1 />
        <AboutLevel2 />
        <AboutLevel3 />
      </IonContent>
    </IonPage>
  )
}
