import { fileToBase64String, selectImage } from '@beenotung/tslib/file'
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonContent,
  IonButtons,
  IonBackButton,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonTextarea,
  IonAvatar,
  IonButton,
} from '@ionic/react'
import { GetMyProfileForEdit, PostMyProfile } from 'web-server'
import { ErrorMessage } from '../components/ErrorMessage'
import { PageTitle } from '../components/PageTitle'
import { postAPI, toAvatarUrl, uploadImage } from '../global/api'
import { routes } from '../global/routes'
import { useGet } from '../hooks/use-get'
import { useObject } from '../hooks/use-object'
import styles from './EditProfilePage.module.scss'
import { wrapToken } from '../components/wrap-token'

type Profile = (typeof GetMyProfileForEdit)['output']['profile']

export const EditProfilePage = wrapToken(({ token }) => {
  const profile = useGet<typeof GetMyProfileForEdit>('my-profile-for-edit', {
    token,
  })
  const state = useObject({
    image_file: null as File | null,
    image_preview_url: '',
    nickname: '',
    bio: '',
    diet_habit: '',
    sport_habit: '',
    error: '',
  })
  const removeAvatar = (profile: Profile) => {
    profile.image_url = ''
    state.patch({ image_file: null, image_preview_url: '' })
  }
  const selectAvatar = async () => {
    let files = await selectImage({ multiple: false })
    let file = files[0]
    if (!file) return
    state.set('image_file', file)
    let url = await fileToBase64String(file)
    state.set('image_preview_url', url)
  }
  const submit = async (profile: Profile) => {
    let image_url = profile.image_url
    if (state.value.image_file) {
      image_url = await uploadImage(state.value.image_file)
    }
    state.set('error', '')
    await postAPI<typeof PostMyProfile>('my-profile', {
      token,
      image_url,
      nickname: state.value.nickname || profile.nickname,
      bio: state.value.bio || profile.bio,
      diet_habit: state.value.diet_habit || profile.diet_habit,
      sport_habit: state.value.sport_habit || profile.sport_habit,
    }).then(output => {
      if (!output.error) {
        profile.image_url = image_url
        profile.nickname = state.value.nickname || profile.nickname
        profile.bio = state.value.bio || profile.bio
        profile.diet_habit = state.value.diet_habit || profile.diet_habit
        profile.sport_habit = state.value.sport_habit || profile.sport_habit
        state.reset()
      }
      state.set('error', output.error || 'Updated Profile')
    })
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton defaultHref={routes.my_profile} />
          </IonButtons>
          <PageTitle>Edit Profile</PageTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        {profile.render({
          name: 'User Profile',
          render: data => (
            <>
              <IonList>
                <IonItem>
                  <IonLabel>Avatar</IonLabel>
                  <IonAvatar className={styles.avatar}>
                    <img
                      src={
                        state.value.image_preview_url ||
                        toAvatarUrl(
                          data.profile.image_url,
                          state.value.nickname ||
                            data.profile.nickname ||
                            data.profile.id,
                        )
                      }
                      alt="user avatar"
                    />
                  </IonAvatar>
                  <div slot="end" className="d-flex flex-column">
                    <IonButton
                      disabled={
                        !data.profile.image_url && !state.value.image_file
                      }
                      slot="end"
                      onClick={() => removeAvatar(data.profile)}
                      color="dark"
                    >
                      Remove
                    </IonButton>
                    <IonButton slot="end" onClick={selectAvatar}>
                      Change
                    </IonButton>
                  </div>
                </IonItem>
                <IonItem>
                  <IonLabel color="medium" position="floating">
                    Login Email
                  </IonLabel>
                  <IonInput
                    color="medium"
                    type="text"
                    value={data.profile.email_hash}
                    readonly
                  ></IonInput>
                </IonItem>
                <IonItem>
                  <IonLabel position="floating">Nickname</IonLabel>
                  <IonInput
                    type="text"
                    value={state.value.nickname || data.profile.nickname}
                    onIonChange={e =>
                      state.set('nickname', e.detail.value || '')
                    }
                  ></IonInput>
                </IonItem>
                <IonItem>
                  <IonLabel position="floating">Diet Habit</IonLabel>
                  <IonTextarea
                    autoGrow
                    autoCapitalize="on"
                    spellCheck
                    value={state.value.diet_habit || data.profile.diet_habit}
                    onIonChange={e =>
                      state.set('diet_habit', e.detail.value || '')
                    }
                    placeholder="What kind of food do you eat usually? How many meal each day?"
                  ></IonTextarea>
                </IonItem>
                <IonItem>
                  <IonLabel position="floating">Sport Habit</IonLabel>
                  <IonTextarea
                    autoGrow
                    autoCapitalize="on"
                    spellCheck
                    value={state.value.sport_habit || data.profile.sport_habit}
                    onIonChange={e =>
                      state.set('sport_habit', e.detail.value || '')
                    }
                    placeholder="What kind of sport do you do usually? How frequently and how long is each session?"
                  ></IonTextarea>
                </IonItem>
                <IonItem>
                  <IonLabel position="floating">Self Introduction</IonLabel>
                  <IonTextarea
                    autoGrow
                    autoCapitalize="on"
                    spellCheck
                    value={state.value.bio || data.profile.bio}
                    onIonChange={e => state.set('bio', e.detail.value || '')}
                  ></IonTextarea>
                </IonItem>
              </IonList>
              <ErrorMessage error={state.value.error} />
              <IonButton expand="block" onClick={() => submit(data.profile)}>
                Save
              </IonButton>
            </>
          ),
        })}
      </IonContent>
    </IonPage>
  )
})
