import { useCallback, useEffect, useState } from 'react'
import io, { Socket } from 'socket.io-client'

export const useSocket = (url: string, onConnect: (socket: Socket) => void) => {
  const [socket, setSocket] = useState(() => io(url))

  const reconnect = useCallback(() => {
    socket.disconnect()
    setSocket(io(url))
  }, [socket, url])

  // register onConnect listener
  useEffect(() => {
    const cb = () => {
      console.debug('socket connect')
      onConnect(socket)
    }
    socket.on('connect', cb)
    if (socket.connected && !socket.disconnect) {
      cb()
    }
    return () => {
      socket.off('connect', cb)
    }
  }, [socket, onConnect])

  // auto close socket when component is destroyed
  useEffect(() => {
    return () => {
      console.debug('disconnect socket on component destroy')
      socket.disconnect()
    }
  }, [socket])

  return { socket, reconnect }
}
