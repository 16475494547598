import { useCallback, useState } from 'react'

export const EmptyObject = {}

export const useObject = <T>(initialValue: T) => {
  const [state, setState] = useState(initialValue)
  const patch = useCallback(
    (partial: Partial<T>) => setState(state => ({ ...state, ...partial })),
    [setState],
  )
  const set = useCallback(
    <K extends keyof T>(key: K, value: T[K]) => {
      setState(state => {
        if (state[key] === value) return state
        return { ...state, [key]: value }
      })
    },
    [patch, state],
  )
  const reset = useCallback(
    () => setState(initialValue),
    [setState, initialValue],
  )
  const update = useCallback(
    <K extends keyof T>(key: K, updateFn: (value: T[K]) => T[K]) =>
      setState(state => ({ ...state, [key]: updateFn(state[key]) })),
    [setState],
  )
  return {
    value: state,
    update,
    patch,
    set,
    reset,
    setState,
  }
}
