import cert from './badge/certicon.png'
import all from './badge/all-alpha.png'
import crown from './badge/crown.png'
import flower from './badge/flower.png'
import goal from './badge/goal.png'
import jewel from './badge/jewel.png'
import sport from './badge/sport.png'
import star from './badge/star.png'
import wheat from './badge/wheat.png'
import wing from './badge/wing.png'
import bannerH from './banner-h.jpg'
import bannerW from './banner-w.jpg'
import boardGameMap from './board-game-map.jpg'
import casher from './casher.jpeg'
import logo_alpha from './logo_alpha.png'
import medicalBanner from './medical-banner.jpg'
import payment_sport from './payment_sport.png'
import placeholder from './placeholder.webp'
import shelfBottom from './shelf_bottom.jpg'
import shelfMiddle from './shelf_middle.jpg'
import shelfTop from './shelf_top.jpg'
import sportCenter from './sport-center.jpeg'
import supermarket from './supermarket.jpeg'
import ranking from './ranking.png'
import tips from './tips.png'
import sleep from './finish-level/sleep.png'
import chat from './finish-level/chat.png'
import startBarBg from './finish-level/starBar-bg.png'
import starFinish from './finish-level/star-finish.png'
import starNotFinish from './finish-level/star-not-finish.png'

export let assets = {
  logo_alpha,
  bannerW,
  bannerH,
  medicalBanner,
  boardGameMap,
  sportCenter,
  tips,
  ranking,
  supermarket,
  casher,
  payment_sport,
  placeholder,
  shelfBottom,
  shelfMiddle,
  shelfTop,
  finishLevel: {
    chat,
    startBarBg,
    starFinish,
    starNotFinish,
    sleep,
  },
  badge: {
    cert,
    all,
    crown,
    flower,
    goal,
    jewel,
    sport,
    star,
    wheat,
    wing,
  },
}
