import { useEffect, useState } from 'react'
import { MINUTE, SECOND } from '@beenotung/tslib/time'

export const useCountDown = (endTime: number) => {
  const [now, setNow] = useState(Date.now())
  useEffect(() => {
    setNow(Date.now())
  }, [endTime])
  const left = endTime - now
  let interval: number
  let leftText: string
  if (left < 0) {
    interval = 0
    leftText = "Time's Up"
  } else if (left < SECOND) {
    interval = SECOND / 10
    leftText = (left / SECOND).toFixed(1) + ' second left'
  } else if (left < MINUTE) {
    interval = SECOND / 10
    leftText = (left / SECOND).toFixed(1) + ' seconds left'
  } else {
    interval = MINUTE / 10
    leftText = (left / MINUTE).toFixed(1) + ' minutes left'
  }
  useEffect(() => {
    if (!left || left < 0) return
    let timer = setTimeout(() => {
      setNow(Date.now())
    }, interval)
    return () => clearTimeout(timer)
  }, [left, interval])
  return { now, left, leftText }
}
