import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonBackButton,
  IonButtons,
} from '@ionic/react'
import { routes } from '../global/routes'

export const PrivacyPage = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton defaultHref={routes.more} />
          </IonButtons>
          <IonTitle>Privacy Policy Statement</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <p>
          Please read this Privacy Policy Statement (“PPS”) to understand our
          policy and practices on the collection, use, transfer, storage and
          processing of your personal data. By accessing this Application, you
          are consenting to this PPS.
        </p>
        <p>
          1. The Hong Kong Polytechnic University (“PolyU”, “we”, “us” or “our”)
          pledges to comply with the requirements of the Personal Data (Privacy)
          Ordinance. In doing so, we will ensure compliance by our staff with
          the highest security and confidentiality standards.
        </p>
        <p>
          2. We protect the privacy of users of our Application. Any personal
          data collected from you will only be used for the specific purposes
          mentioned at the time of collection or for purposes directly related
          to those specific purposes and/or that mentioned in the relevant
          Personal Information Collection Statement.
        </p>
        <p>
          3. We will retain your personal data only for so long as necessary to
          fulfill the purpose of collection; and will erase your personal data
          thereafter. We will ensure the security of your personal data and
          protect them from unauthorized access.
        </p>
        <p>
          4. We will keep your personal data confidential. Your personal data
          will be used (and disclosed) to third parties for the purposes for
          which they were collected, and where we are required to do so by law
          and as specified in the relevant Personal Information Collection
          Statement.
        </p>
        <p>
          5. We may keep an activity log that does not identify you individually
          and cannot be used to determine the particular user in order to
          measure traffic, gauge the popularity of different parts of our
          Application, gain general knowledge about the users, and facilitate
          the enhancement of our Application. This information may include the
          IP address, operating system and version, the type of network, the
          geo- location information and identifiers of the device you used.
        </p>
        <p>
          6. This PPS is subject to change. Any changes will be posted on this
          page.
        </p>
      </IonContent>
    </IonPage>
  )
}
