import { IonModal } from '@ionic/react'

export let IonModalWrapper = (props: {
  children: JSX.Element | JSX.Element[]
  isOpen: boolean
  backdropDismiss?: boolean
  showBackdrop?: boolean
  swipeToClose?: boolean
  onDidDismiss?: () => void
}) => (
  <div>
    <IonModal {...props} />
  </div>
)
