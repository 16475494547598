import {
  IonButton,
  IonButtons,
  IonIcon,
  IonSearchbar,
  IonText,
} from '@ionic/react'
import { helpCircle } from 'ionicons/icons'

export const AboutLevel1 = () => {
  return (
    <>
      <h2>Level 1: Sweet Adventure (Board Game)</h2>
      <p>
        Players walk through the forest while fighting against the temptations
        from the sweet devils to reach the Health Goal Point.
      </p>
      <h3>Chess rules</h3>
      <ol>
        <li>
          Begin your turn by rolling the dice and advance the tokens in the game
          board.
        </li>
        <li>
          Answer the pop-up multiple choice questions. One attempt for each
          question.
        </li>
        <li>
          Once you have answered the multiple choice question correctly, a gold
          coin will be deposited to your account.
        </li>
        <li>
          You may have privileges or penalties, depending on the space that you
          land.
        </li>
        <li>
          The e-Game ends when you reach the Health Goal Point or when time runs
          out (5 minutes).
        </li>
      </ol>
      <h3>Waiting Room</h3>
      <p>
        In the waiting room, you can search a room to <b>Join</b> or{' '}
        <b>Create</b> a new room.
      </p>
      <p>
        Only the room owner can <b>Start</b> the game.
      </p>

      <h4>Visual Guide</h4>

      <fieldset style={{ display: 'inline-block' }}>
        <legend>Join Button</legend>
        <IonButton size="small" color="primary">
          Join
        </IonButton>
      </fieldset>

      <fieldset style={{ display: 'inline-block' }}>
        <legend>Start button</legend>
        <IonButton size="small" color="success">
          Start
        </IonButton>
      </fieldset>

      <fieldset style={{ display: 'inline-block' }}>
        <legend>Search Room</legend>
        <IonSearchbar placeholder="Enter room name"></IonSearchbar>
      </fieldset>

      <fieldset style={{ display: 'inline-block' }}>
        <legend>Create New Room</legend>
        <IonSearchbar placeholder="Enter room name"></IonSearchbar>

        <div className="ion-text-end">
          <IonButton size="small" fill="outline">
            Create New Room
          </IonButton>
          <div>
            <IonText color="danger">(Input room name above to create)</IonText>
          </div>
        </div>
      </fieldset>

      <fieldset style={{ display: 'inline-block' }}>
        <legend>Help Button to show guidelines</legend>
        <IonButtons>
          <IonButton color="tertiary">
            <IonIcon icon={helpCircle}></IonIcon>
            Guidelines
          </IonButton>
        </IonButtons>
      </fieldset>
    </>
  )
}

export let ToLevel2Message = () => {
  return (
    <>
      <p>
        Congratulations! <br />
        Your account is loaded up with lots of gold coins. <br />
        Ready to take on new challenges in health literacy in diabetes care.
      </p>
    </>
  )
}
