import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  useIonRouter,
} from '@ionic/react'
import { assets } from '../assets'
import { PageTitle } from '../components/PageTitle'
import { routes } from '../global/routes'
import styles from './FinishLevel.module.scss'
import { feedbackPage } from './FeedbackPage'

const StarIcon = (props: { currentLevel: number; starLevel: number }) => {
  let isFinish = props.currentLevel >= props.starLevel
  return (
    <div
      className={isFinish ? styles.starFinish : styles.starNotFinish}
      style={{
        backgroundImage:
          'url(' +
          (isFinish
            ? assets.finishLevel.starFinish
            : assets.finishLevel.starNotFinish) +
          ')',
      }}
    >
      Lv{props.starLevel}
    </div>
  )
}

const StarBar = (props: { currentLevel: number }) => {
  return (
    <div
      className={styles.starBar + ' ion-padding-horizontal'}
      style={{
        backgroundImage: 'url(' + assets.finishLevel.startBarBg + ')',
      }}
    >
      <StarIcon starLevel={1} currentLevel={props.currentLevel} />
      <StarIcon starLevel={2} currentLevel={props.currentLevel} />
      <StarIcon starLevel={3} currentLevel={props.currentLevel} />
    </div>
  )
}

const ChatPointer = (props: { currentLevel: number; starLevel: number }) => {
  let isCurrent = props.currentLevel === props.starLevel
  return (
    <div
      className={styles.chatPointer}
      style={{ opacity: isCurrent ? 1 : 0 }}
    ></div>
  )
}

const ChatMessage = (props: { text: string; currentLevel: number }) => {
  return (
    <div className="ion-padding-horizontal">
      <div className={styles.chatPointerContainer}>
        <ChatPointer starLevel={1} currentLevel={props.currentLevel} />
        <ChatPointer starLevel={2} currentLevel={props.currentLevel} />
        <ChatPointer starLevel={3} currentLevel={props.currentLevel} />
      </div>
      <div className={styles.chatBox}>{props.text}</div>
    </div>
  )
}

export const FinishLevelPage = (props: {
  banner?: JSX.Element
  titleText: string
  titleSize: string
  cheerUpText: string
  doneText: JSX.Element | string
  nextText: string
  currentLevel: number
  nextButton?: JSX.Element
  backUrl: string
}) => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton defaultHref={props.backUrl} />
          </IonButtons>
          <PageTitle fontSize={props.titleSize}>{props.titleText}</PageTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {props.banner}
        <h1 className="ion-text-center" style={{ marginTop: '10px' }}>
          {props.cheerUpText}
        </h1>
        <p className="ion-text-center">{props.doneText}</p>
        <StarBar currentLevel={props.currentLevel} />
        <ChatMessage currentLevel={props.currentLevel} text={props.nextText} />
        {props.nextButton}
      </IonContent>
    </IonPage>
  )
}

export const FinishLevel1Page = () => {
  return (
    <FinishLevelPage
      banner={
        <div className="ion-text-center">
          <img src={assets.finishLevel.sleep} style={{ width: '60%' }} />
        </div>
      }
      titleText="Level 1: Sweet Adventure"
      titleSize="1.1rem"
      cheerUpText="Congratulations!!"
      doneText="Your account is loaded up with lots of gold coins."
      nextText="It’s time to have a treat and workout!"
      currentLevel={1}
      backUrl={routes.board_game.waiting_room}
      nextButton={
        <IonButton
          routerLink={routes.shop.home}
          expand="block"
          className="ion-margin-horizontal"
        >
          Go to Level 2 {'>'}
        </IonButton>
      }
    />
  )
}

export const FinishLevel2Page = () => {
  return (
    <FinishLevelPage
      banner={
        <div className="ion-text-center">
          <img src={assets.sportCenter} style={{ width: '70%' }} />
        </div>
      }
      titleText="Level 2: Food &amp; Exercise Extravaganza"
      titleSize="1.0rem"
      cheerUpText="Well Done!!"
      doneText="Your healthy food choice and physical fitness have earned you a health report."
      nextText="Share your featured food menu and see if they are compatible with the diabetes food guidelines."
      currentLevel={2}
      backUrl={routes.shop.home}
      nextButton={
        <IonButton
          routerLink={routes.photo_feed.home}
          expand="block"
          className="ion-margin-horizontal"
        >
          Go to Level 3 {'>'}
        </IonButton>
      }
    />
  )
}

export const FinishLevel3Page = () => {
  const router = useIonRouter()
  function gotoNext() {
    feedbackPage.is_from_photo_feed = true
    router.push(routes.feedback, 'root')
  }
  return (
    <FinishLevelPage
      banner={
        <div className="ion-text-center">
          <img src={assets.logo_alpha} style={{ width: '50%' }} />
        </div>
      }
      titleText="Level 3: Featured DM Diet"
      titleSize="1.1rem"
      cheerUpText="Wonderful!!"
      doneText="You have set the trend of healthy food choice for diabetes self-management."
      nextText="Keep on transforming your community with DM diet."
      currentLevel={3}
      backUrl={routes.photo_feed.home}
      nextButton={
        <IonButton
          expand="block"
          className="ion-margin-horizontal"
          onClick={gotoNext}
        >
          Provide Feedback
        </IonButton>
      }
    />
  )
}
