import { useCallback, useState } from 'react'

export const useRerender = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, setState] = useState(1)
  const rerender = useCallback(() => {
    setState(state => state + 1)
  }, [])
  return rerender
}
