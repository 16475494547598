import { IonTitle } from '@ionic/react'
import { isIPhone } from '../global/platform'
import React from 'react'

export const PageTitle = (props: {
  fontSize?: string
  children: string | any
}) => {
  return (
    <IonTitle
      className={isIPhone ? 'ion-no-padding' : ''}
      style={{ fontSize: props.fontSize }}
    >
      {props.children}
    </IonTitle>
  )
}
