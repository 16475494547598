import { useEffect } from 'react'
import {
  cartOutline,
  cashOutline,
  cashSharp,
  fileTrayStackedOutline,
  informationCircleOutline,
  pricetagsOutline,
  pricetagsSharp,
} from 'ionicons/icons'
import {
  IonAccordion,
  IonAccordionGroup,
  IonButton,
  IonButtons,
  IonCard,
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemGroup,
  IonLabel,
  IonList,
  IonText,
  IonThumbnail,
  IonToolbar,
} from '@ionic/react'
import { postAPI, toImageUrl } from '../global/api'
import styles from './Shop.module.scss'
import { PageTitle } from './PageTitle'
import { useGet, useGetAutoRefresh } from '../hooks/use-get'
import {
  GetShopItemDetail,
  GetSupermarket,
  PostShoppingCartItem,
} from 'web-server'
import { useObject } from '../hooks/use-object'
import { ErrorMessage } from './ErrorMessage'
import { routes } from '../global/routes'
import { assets } from '../assets'
import { useRerender } from '../hooks/use-rerender'
import { config } from '../config'
import { Tips } from './Tips'
import { IonModalWrapper } from './IonModalWrapper'

const ShopItemDetailModalBody = (props: { item_id: number }) => {
  const state = useGet<typeof GetShopItemDetail>('shop-item-detail', {
    id: props.item_id,
  })
  return (
    <>
      {state.render({
        name: 'Item Detail',
        render: data => (
          <>
            <IonThumbnail
              className={styles.thumbnail + ' ' + styles.thumbnailInModal}
            >
              <img
                src={toImageUrl(data.item.image_url)}
                alt={'illustration of ' + data.item.name}
              ></img>
            </IonThumbnail>
            <div className="ion-text-center">
              <IonText color="primary">
                <h3>{data.item.name}</h3>
              </IonText>
            </div>
            <IonList>
              <IonItem>
                <IonIcon
                  color="primary"
                  icon={fileTrayStackedOutline}
                  slot="start"
                ></IonIcon>
                <IonLabel>{data.item.category}</IonLabel>
              </IonItem>
              <IonItem>
                <IonIcon
                  color="warning"
                  icon={cashOutline}
                  slot="start"
                ></IonIcon>
                <IonLabel>cost {data.item.price} coins</IonLabel>
              </IonItem>
              <IonItem
                hidden={!data.item.is_healthy || config.hideHealthyFoodHint}
              >
                <IonIcon
                  color="success"
                  icon={pricetagsOutline}
                  slot="start"
                ></IonIcon>
                <IonLabel>earn {data.item.price / 2} coins</IonLabel>
              </IonItem>
              <IonItem hidden={!data.item.desc} className="ion-margin-bottom">
                <IonIcon
                  color="tertiary"
                  icon={informationCircleOutline}
                  slot="start"
                ></IonIcon>
                <p className="ion-text-wrap">
                  {data.item.desc || 'No description available yet'}
                </p>
              </IonItem>
            </IonList>
          </>
        ),
      })}
    </>
  )
}

export const SupermarketList = (props: {
  token: string
  user_coins: undefined | number
  refreshCounter: number
  stats: JSX.Element | null
}) => {
  const { token, refreshCounter } = props
  const supermarket = useGetAutoRefresh<typeof GetSupermarket>('supermarket', {
    token,
  })
  const state = useObject({
    width: 0,
    detail_item_id: 0,
  })
  const cartError = useObject<Record<number, string>>({})
  const rerender = useRerender()
  const hasItemInCart = supermarket
    .getValue()
    ?.category_list.some(cat => cat.food_list.some(food => food.cart > 0))

  useEffect(() => {
    supermarket.reload()
  }, [refreshCounter])

  let spending = 0
  if (supermarket.state.status === 'ready') {
    supermarket.state.value.category_list.forEach(cat => {
      cat.food_list.forEach(food => {
        if (food.cart) {
          spending += food.cart * food.price
        }
      })
    })
  }
  let remindCoins = (props.user_coins || 0) - spending

  const setCart = (food: { id: number; cart: number }, delta: number) => {
    let quantity = food.cart || 0
    quantity += delta
    if (quantity < 0) {
      quantity = 0
    }
    postAPI<typeof PostShoppingCartItem>('shopping-cart-item', {
      token,
      item_id: food.id,
      delta,
    }).then(output => {
      cartError.set(food.id, output.error)
      if (!output.error) {
        food.cart = quantity
        supermarket.rerender()
      }
    })
  }
  return (
    <>
      <IonModalWrapper
        isOpen={!!state.value.detail_item_id}
        showBackdrop
        swipeToClose
        onDidDismiss={() => state.set('detail_item_id', 0)}
      >
        <IonHeader>
          <IonToolbar color="primary">
            <PageTitle>Item Detail</PageTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => state.set('detail_item_id', 0)}>
                Close
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {state.value.detail_item_id ? (
            <ShopItemDetailModalBody item_id={state.value.detail_item_id} />
          ) : null}
        </IonContent>
      </IonModalWrapper>

      <Tips text="Add goodies in the shopping cart and pay at the cashier. Your choices earn you health points." />

      {props.stats}

      {supermarket.render({
        name: 'Shop Items',
        render: data => (
          <IonList>
            <IonItemGroup>
              <IonAccordionGroup>
                {data.category_list.map(({ category, food_list }) => (
                  <IonAccordion key={category.id}>
                    <IonItem slot="header">
                      <IonLabel className="ion-text-wrap">
                        {category.name}
                      </IonLabel>
                      <IonThumbnail slot="start">
                        <img
                          src={
                            '/assets/image/shop/category/' +
                            category.id +
                            '.png'
                          }
                          alt={'icon of ' + category.name}
                        />
                      </IonThumbnail>
                    </IonItem>
                    <div slot="content">
                      <div className={styles.shelfBorderContainer}>
                        <img src={assets.shelfTop} />
                      </div>
                      <div className={styles.shelfMiddleContainer}>
                        <img src={assets.shelfMiddle} />
                        {food_list.map(food => (
                          <div
                            className={styles.foodInShelf}
                            key={food.id}
                            hidden={food.name === 'goose'}
                          >
                            <IonCard className="ion-no-margin ion-margin-bottom ion-padding">
                              <div className={styles.imageContainer}>
                                <img src={toImageUrl(food.image_url)} />
                              </div>
                              <div className="ion-text-center">
                                <b>{food.name}</b>
                              </div>
                              <div>
                                <IonIcon color="warning" icon={cashSharp} />{' '}
                                cost {food.price} coins
                              </div>
                              <div
                                hidden={
                                  !category.is_healthy ||
                                  config.hideHealthyFoodHint
                                }
                              >
                                <IonIcon
                                  color="success"
                                  icon={pricetagsSharp}
                                />{' '}
                                earn {food.price / 2} coins
                              </div>
                              <IonButton
                                hidden
                                color="tertiary"
                                fill="outline"
                                size="small"
                                expand="block"
                                onClick={() =>
                                  state.set('detail_item_id', food.id)
                                }
                              >
                                Details
                              </IonButton>
                              {food.cart ? (
                                <>
                                  <div className="d-flex ion-align-items-center ion-justify-content-between">
                                    <IonButton
                                      fill="outline"
                                      size="small"
                                      expand="block"
                                      onClick={() => {
                                        setCart(food, -1)
                                      }}
                                    >
                                      -1
                                    </IonButton>
                                    <span>x{food.cart}</span>
                                    <IonButton
                                      fill="outline"
                                      size="small"
                                      expand="block"
                                      disabled={remindCoins <= food.price}
                                      onClick={() => {
                                        setCart(food, +1)
                                      }}
                                    >
                                      +1
                                    </IonButton>
                                  </div>
                                </>
                              ) : (
                                <IonButton
                                  fill="outline"
                                  size="small"
                                  expand="block"
                                  disabled={remindCoins <= food.price}
                                  onClick={() => {
                                    setCart(food, +1)
                                  }}
                                >
                                  Add to cart
                                </IonButton>
                              )}
                              <ErrorMessage error={cartError.value[food.id]} />
                            </IonCard>
                          </div>
                        ))}
                      </div>
                      <div className={styles.shelfBorderContainer}>
                        <img src={assets.shelfBottom} />
                      </div>
                    </div>
                  </IonAccordion>
                ))}
              </IonAccordionGroup>
            </IonItemGroup>
          </IonList>
        ),
      })}

      <IonFab slot="fixed" horizontal="end" vertical="bottom">
        <IonFabButton
          routerLink={routes.shop.supermarket_checkout}
          disabled={!hasItemInCart}
        >
          <IonIcon icon={cartOutline}></IonIcon>
        </IonFabButton>
      </IonFab>
    </>
  )
}
