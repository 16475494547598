import {
  IonAlert,
  IonAvatar,
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonFab,
  IonFabButton,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonModal,
  IonPage,
  IonProgressBar,
  IonText,
  IonToolbar,
  useIonRouter,
} from '@ionic/react'
import Dice from 'react-dice-roll'
import { postAPI, callAPI, SERVER_ORIGIN, toAvatarUrl } from '../global/api'
import { routes } from '../global/routes'
import styles from './BoardGameChessRoom.module.scss'
import { EmptyObject, useObject } from '../hooks/use-object'
import {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import $ from 'jquery'
import { PageTitle } from '../components/PageTitle'
import { useGet } from '../hooks/use-get'
import {
  ChessMoveEvent,
  GetBoardGameChessRoom,
  GetBoardGameSummary,
  PostBoardGameChessMove,
  GetBoardGameQuestion,
  GetBoardGameCoinGain,
  ChessQuestionEvent,
  ChessAnswerEvent,
} from 'web-server'
import { caretUp, cashOutline, refresh } from 'ionicons/icons'
import { assets } from '../assets'
import { wrapToken } from '../components/wrap-token'
import { useSocket } from '../hooks/use-socket'
import { useRerender } from '../hooks/use-rerender'
import { fromTimestamp, toPlayerNickname } from '../global/format'
import { useCountDown } from '../hooks/use-count-down'
import { ErrorMessage } from '../components/ErrorMessage'
import Confetti from 'react-confetti'
import { SECOND } from '@beenotung/tslib/time'
import { useS } from '../hooks/use-s'
import useStorageState from 'react-use-storage-state'
import { config } from '../config'

const W = 3
const H = 14
const N = W * H

const { indexList, matrix } = createIndexList()

function createIndexList() {
  let indexList: number[] = []
  let matrix: number[][] = []
  let index = N
  for (let y = 0; y < H; y++) {
    let buffer = []
    for (let x = 0; x < W; x++) {
      buffer.push(index)
      index--
    }
    if (y % 2 === 1) {
      buffer.reverse()
    }
    indexList.push(...buffer)
    matrix.push(buffer)
  }
  return { indexList, matrix }
}

export const BoardGameChessRoomPage = wrapToken(({ token }) => {
  const chessRoom = useGet<typeof GetBoardGameChessRoom>(
    'board-game-chess-room',
    { token },
  )
  const chessRoomValue = chessRoom.getValue()
  const user_list = chessRoomValue?.user_list
  const user_id = chessRoomValue?.user_id
  const room = chessRoomValue?.room
  const room_start_time = room ? fromTimestamp(room.start_time).getTime() : 0
  const room_end_time = room_start_time + chessRoomValue?.MaxChessGameDuration!
  const is_game_over = user_list?.some(user => user.index_on_map >= N)
  const { left: gameLeftTime, leftText: gameLeftTimeText } = useCountDown(
    is_game_over ? 0 : room_end_time,
  )
  const has_end = gameLeftTime <= 0
  const gameSummary = useGet<typeof GetBoardGameSummary>(
    'board-game-summary',
    !room || !has_end ? 'skip' : { token, room_id: room.id },
  )
  const gameStats = gameSummary.getValue()?.stats
  const isHideGameStats = useS(false)
  const coinGain = useGet<typeof GetBoardGameCoinGain>(
    'board-game-coin-gain',
    !room ? 'skip' : { token, room_id: room.id },
  )
  const coin_gain = coinGain.getValue()?.coin_gain

  const round = room?.round

  const last_move_time = room?.last_move_time
    ? fromTimestamp(room?.last_move_time).getTime()
    : null
  const move_end_time = last_move_time
    ? last_move_time + chessRoomValue?.MaxChessGameStepDuration!
    : 0
  const { leftText: stepLeftTimeText, left: stepLeftTime } =
    useCountDown(move_end_time)

  const current_player =
    room && user_list ? user_list[room.round % user_list.length] : null
  const current_player_id = current_player?.id
  const is_current_player = current_player_id === user_id

  const can_dice =
    !is_game_over &&
    !has_end &&
    is_current_player &&
    (round === 0 || stepLeftTime > 0)

  useEffect(() => {
    if (is_current_player) {
      playMusic()
    }
  }, [is_current_player])

  useEffect(() => {
    if (!is_game_over && is_current_player) {
      return () => {
        coinGain.reload()
      }
    }
  }, [is_current_player, is_game_over])

  const state = useObject({
    moveError: '',
    question: null as typeof GetBoardGameQuestion['output']['question'],
    question_user_id: undefined as number | undefined,
    step: 1,
  })
  const patchState = state.patch
  const { moveError, question, question_user_id, step } = state.value

  const stepUnit = step > 1 ? 'steps' : 'step'

  // user_id -> index
  const playerProgressOnMap = useObject<Record<number, number>>(EmptyObject)

  const ionContent = useRef<HTMLIonContentElement>(null)

  const [chessMoveEvent, setChessMoveEvent] = useState<ChessMoveEvent | null>(
    null,
  )
  const [chessAnswerEvent, setChessAnswerEvent] =
    useState<ChessAnswerEvent | null>(null)
  const [chessQuestionEvent, setChessQuestionEvent] =
    useState<ChessQuestionEvent | null>(null)
  const { socket, reconnect } = useSocket(SERVER_ORIGIN, socket => {
    console.log('login socket:', socket.id)
    socket.emit('login', token)
    socket.on('chess_move', event => setChessMoveEvent(event))
    socket.on('chess_question', event => setChessQuestionEvent(event))
    socket.on('chess_answer', event => setChessAnswerEvent(event))
  })

  const selected_answer_id =
    chessAnswerEvent?.batch_id == question?.batch_id
      ? chessAnswerEvent?.selected_answer_id
      : undefined
  const correct_answer_id =
    chessAnswerEvent?.batch_id == question?.batch_id
      ? chessAnswerEvent?.correct_answer_id
      : undefined
  const is_correct =
    !selected_answer_id || !correct_answer_id
      ? undefined
      : selected_answer_id == correct_answer_id

  const has_passed_question =
    chessMoveEvent &&
    chessQuestionEvent &&
    chessMoveEvent.timestamp > chessQuestionEvent.timestamp
  const rerender = useRerender()
  useEffect(() => {
    if (!chessMoveEvent || !user_list || !room) return
    console.log('chess_move', chessMoveEvent)
    let user = user_list.find(user => user.id === chessMoveEvent.user_id)
    if (!user) return
    user.index_on_map = chessMoveEvent.to_index
    room.round = chessMoveEvent.round
    room.last_move_time = new Date(chessMoveEvent.timestamp).toISOString()
    rerender()
  }, [chessMoveEvent, user_list, room, rerender, patchState])
  useEffect(() => {
    if (!chessQuestionEvent) return
    patchState({
      question: chessQuestionEvent.question,
      question_user_id: chessQuestionEvent.user_id,
    })
  }, [chessQuestionEvent, patchState])

  const canvasSize = useObject({ width: 0, height: 0 })
  const [isShowingConfetti, setIsShowingConfetti] = useState(false)

  useEffect(() => {
    if (!isShowingConfetti) return
    let timer = setTimeout(() => {
      setIsShowingConfetti(false)
    }, 10 * SECOND)
    return () => {
      clearTimeout(timer)
    }
  }, [isShowingConfetti])

  useLayoutEffect(() => {
    const ionContentElement = ionContent.current
    const offsetRect = ionContentElement
      ?.querySelector('.' + styles.gameMapContainer)
      ?.getBoundingClientRect()
    if (!ionContentElement || !user_list || !offsetRect) return
    if (
      canvasSize.value.width !== offsetRect.width ||
      canvasSize.value.height !== offsetRect.height
    ) {
      canvasSize.setState({
        width: offsetRect.width,
        height: offsetRect.height,
      })
    }
    let players = user_list
      .slice()
      .sort((b, a) => b.index_on_map - a.index_on_map)
    players.forEach(user => {
      let index_on_map = user.index_on_map
      if (index_on_map > N) {
        index_on_map = N
      }
      if (index_on_map < 1) {
        index_on_map = 1
      }
      if (index_on_map === playerProgressOnMap.value[user.id]) {
        return
      }
      if (index_on_map === N) {
        setIsShowingConfetti(true)
      }
      const playerDiv = ionContentElement.querySelector(
        `[data-player="${user.id}"]`,
      )
      const indexDiv = ionContentElement.querySelector(
        `[data-index="${index_on_map}"]`,
      )
      if (!playerDiv || !indexDiv) return
      let rect = indexDiv.getBoundingClientRect()
      if (rect.height === 0 || rect.width === 0) return
      indexDiv.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      })
      $(playerDiv).animate({
        top: rect.top - offsetRect.top,
        left: rect.left - offsetRect.left,
      })
      playerProgressOnMap.set(user.id, index_on_map)
    })
  })
  const closeQuestion = () => {
    patchState({
      question: null,
    })
  }
  const move = useCallback(
    async (options: {
      step: number
      question: null | { answer_id: number; batch_id: number }
    }) => {
      postAPI<typeof PostBoardGameChessMove>('board-game-chess-move', {
        step: options.step,
        token,
        answer: options.question
          ? {
              answer_id: options.question.answer_id,
              batch_id: options.question.batch_id,
            }
          : null,
      }).then(output => {
        patchState({
          moveError: output.error,
          // question: null,
        })
      })
    },
    [patchState, token],
  )
  useEffect(() => {
    if (
      !is_game_over &&
      current_player?.id === user_id &&
      current_player?.index_on_map === N
    ) {
      move({ step: 0, question: null })
    }
  }, [current_player, user_id, move, is_game_over])
  const onRoll = (step: number) => {
    callAPI<typeof GetBoardGameQuestion>('get', 'board-game-question', {
      token,
    }).then(output => {
      patchState({
        question: output.question,
        question_user_id: user_id,
      })
      if (output.question) {
        patchState({ step })
      } else {
        move({ step, question: null })
      }
    })
  }
  const submitAnswer = (question: { batch_id: number; answer_id: number }) => {
    move({ step, question })
  }
  const reload = () => {
    playerProgressOnMap.reset()
    chessRoom.reload()
    gameSummary.reload()
    coinGain.reload()
    reconnect()
  }
  const router = useIonRouter()
  const [have_seen_level_1_finish, set_have_seen_level_1_finish] =
    useStorageState('have_seen_level_1_finish', false)
  function gotoNextLevel() {
    set_have_seen_level_1_finish(true)
    router.push(routes.board_game.finish_level1)
  }
  const question_user =
    state.value.question_user_id === user_id
      ? 'you'
      : user_list?.find(user => user.id === state.value.question_user_id)
          ?.nickname || 'player'
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons
            slot="start"
            hidden={chessRoomValue && !has_end && !is_game_over}
          >
            <IonBackButton defaultHref={routes.board_game.waiting_room} />
          </IonButtons>
          <PageTitle>Board Game Room</PageTitle>
          <IonButtons slot="end">
            <IonButton onClick={reload}>
              <IonIcon icon={refresh}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
        {chessRoom.render({
          render: data => {
            const progress = 1 - gameLeftTime / data.MaxChessGameDuration
            return (
              <IonToolbar color="primary">
                <IonButtons slot="start">{data.room.name}</IonButtons>
                <IonButtons slot="end">
                  {is_game_over ? ' (Game Over) ' : gameLeftTimeText}
                </IonButtons>
                <IonProgressBar
                  buffer={0}
                  value={progress}
                  color="success"
                ></IonProgressBar>
              </IonToolbar>
            )
          },
        })}
      </IonHeader>
      <IonContent className="ion-no-padding" ref={ionContent}>
        <div className={styles.gameMapContainer}>
          <table
            className={styles.gameMap}
            style={{ backgroundImage: 'url(' + assets.boardGameMap + ')' }}
          >
            <tbody>
              {matrix.map((xs, y) => (
                <tr key={y}>
                  {xs.map((index, x) => (
                    <td key={x}>
                      <div className={styles.cell} data-index={index}>
                        {/* {index} */}
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          {user_list?.map(player => (
            <Fragment key={player.id}>
              <div
                className={styles.player}
                data-player={player.id}
                style={{
                  zIndex: player.id === current_player_id ? 1 : undefined,
                }}
              >
                <div className={styles.playerInner}>
                  <IonAvatar>
                    <img
                      src={toAvatarUrl(
                        player.image_url,
                        player.nickname || player.id,
                      )}
                      alt={'avatar of ' + player.nickname}
                    />
                  </IonAvatar>
                  {player.id === user_id ? (
                    <IonText color="primary">
                      <b>{player.nickname}</b>
                    </IonText>
                  ) : (
                    <IonText color="dark">{player.nickname}</IonText>
                  )}
                </div>
              </div>
            </Fragment>
          ))}
          {isShowingConfetti ? (
            <Confetti
              width={canvasSize.value.width}
              height={canvasSize.value.height}
              recycle={isShowingConfetti}
              // numberOfPieces={200 * 5}
              drawShape={ctx => {
                ctx.lineWidth = 3
                ctx.beginPath()
                for (let i = 0; i < 22; i++) {
                  const angle = 0.35 * i
                  const x = (0.2 + 1.5 * angle) * Math.cos(angle)
                  const y = (0.2 + 1.5 * angle) * Math.sin(angle)
                  ctx.lineTo(x, y)
                }
                ctx.stroke()
                ctx.closePath()
              }}
            />
          ) : null}
          {gameStats && !isHideGameStats() ? (
            <>
              <dialog
                open
                style={{ top: 0, bottom: 0, left: 0, right: 0, margin: 'auto' }}
              >
                <b>Game Summary:</b>
                <p>
                  Gained {gameStats.coin_gain}{' '}
                  <IonIcon color="warning" icon={cashOutline} />
                </p>
                <p>
                  {gameStats.n_correct} correct, {gameStats.n_wrong} wrong
                  attempts
                </p>
                <div className="ion-text-center">
                  {(!have_seen_level_1_finish ||
                    config.showFinishLevelEveryTime) &&
                  gameStats.index_on_map >= N ? (
                    <IonButton onClick={gotoNextLevel}>Next</IonButton>
                  ) : (
                    <IonButton onClick={() => isHideGameStats(true)}>
                      Close
                    </IonButton>
                  )}
                </div>
              </dialog>
            </>
          ) : null}
        </div>
        <IonAlert
          isOpen={!!moveError}
          header="Cannot move chess"
          message={moveError}
        />
        <IonModal
          isOpen={!!question}
          showBackdrop={false}
          swipeToClose={false}
          onIonModalDidDismiss={() => patchState({ question: null })}
        >
          {question ? (
            <>
              <IonHeader>
                <IonToolbar color="primary">
                  <PageTitle>Bonus Question</PageTitle>
                </IonToolbar>
              </IonHeader>
              <IonContent className="ion-padding">
                <p style={{ margin: '0.5rem 0' }}>
                  If {question_user} answer <b>correctly</b>, {question_user}'ll
                  move <b>+{step * 2} steps</b>; otherwise, {question_user}'ll
                  move{' '}
                  <b>
                    -{step} {stepUnit}
                  </b>
                </p>
                <p style={{ margin: '0.5rem 0' }}>
                  (Category: {question.category})
                </p>
                {has_end || is_game_over ? null : <b>({stepLeftTimeText})</b>}
                <IonList>
                  <IonListHeader>{question.question}</IonListHeader>
                  {question.answers.map(answer => (
                    <IonItem
                      key={answer.id}
                      color={
                        answer.id == correct_answer_id
                          ? is_correct
                            ? 'success'
                            : 'danger'
                          : undefined
                      }
                    >
                      <IonLabel className="ion-text-wrap">
                        {answer.answer}
                      </IonLabel>
                      <IonButton
                        expand="block"
                        onClick={() =>
                          submitAnswer({
                            answer_id: answer.id,
                            batch_id: question.batch_id,
                          })
                        }
                        hidden={!!selected_answer_id}
                        disabled={
                          !is_current_player || question_user_id !== user_id
                        }
                      >
                        Choose
                      </IonButton>
                      <IonButton
                        expand="block"
                        disabled
                        hidden={selected_answer_id !== answer.id}
                        color={is_correct ? 'light' : 'danger'}
                      >
                        {is_correct ? 'Correct' : 'Wrong'}
                      </IonButton>
                    </IonItem>
                  ))}
                </IonList>
                <div
                  style={{ marginBottom: '2.5rem' }}
                  className="ion-text-center"
                >
                  <IonButton
                    hidden={!selected_answer_id && !has_passed_question}
                    onClick={closeQuestion}
                  >
                    Close
                  </IonButton>
                </div>
              </IonContent>
            </>
          ) : null}
        </IonModal>
        <IonFab
          slot="fixed"
          horizontal="end"
          vertical="bottom"
          hidden={!coin_gain}
        >
          <IonFabButton>
            {(coin_gain || 0) > 0 ? '+' + coin_gain : coin_gain}
            &nbsp;
            <IonIcon icon={cashOutline} size="small" color="warning" />
          </IonFabButton>
        </IonFab>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <div hidden={!room?.round} className="ion-text-center">
            <IonText color="dark">
              round {room?.round}
              {has_end || is_game_over ? null : ' : ' + stepLeftTimeText}
            </IonText>
          </div>
          {chessRoom.render({
            name: 'room member list',
            render: data => {
              return (
                <>
                  <div className={styles.memberList}>
                    {data.user_list.map(player => {
                      const is_current_player = player.id === current_player_id
                      const is_self = player.id === data.user_id
                      const nickname = toPlayerNickname(player)
                      return (
                        <div className={styles.roomMember} key={player.id}>
                          <IonAvatar>
                            <img
                              src={toAvatarUrl(
                                player.image_url,
                                player.nickname || player.id,
                              )}
                              alt={'avatar of ' + nickname}
                            />
                          </IonAvatar>
                          <IonText color={is_self ? 'primary' : 'dark'}>
                            <b>{nickname}</b>
                          </IonText>
                          <br />
                          <IonText color="dark">
                            step: {player.index_on_map}
                            <div
                              style={{
                                opacity:
                                  is_current_player && !has_end && !is_game_over
                                    ? 1
                                    : 0,
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              (<IonIcon icon={caretUp} color="primary" />
                              your turn)
                            </div>
                          </IonText>
                        </div>
                      )
                    })}
                  </div>
                  {/* <div>has question?: {!!question ? 'yes' : 'no'}</div>
                  <div>step left time: {String(stepLeftTime)}</div> */}
                  <div className={styles.dice}>
                    <Dice
                      size={50}
                      rollingTime={2000}
                      disabled={!can_dice}
                      onRoll={onRoll}
                    />
                  </div>
                </>
              )
            },
          })}
          <ErrorMessage error={moveError} />
        </IonToolbar>
      </IonFooter>
    </IonPage>
  )
})

function playMusic() {
  let url = '/assets/sound/complete.oga'
  // let url = '/assets/sound/test.wav'
  let audio = document.createElement('audio')
  audio.style.display = 'none'
  document.body.appendChild(audio)
  audio.src = url
  audio.play()
  setTimeout(() => {
    audio.remove()
  }, 5000)
}
