import { IonNote, IonProgressBar } from '@ionic/react'

export const LoadingMessage = (props: { name: string }) => {
  return (
    <div className="ion-text-center">
      <IonNote>Loading {props.name} ...</IonNote>
      <IonProgressBar type="indeterminate"></IonProgressBar>
    </div>
  )
}
