import { IonNote } from '@ionic/react'

export const ErrorMessage = (props: { error: string }) => {
  return (
    <>
      {props.error && (
        <div className="ion-text-center">
          <IonNote color="danger">{props.error}</IonNote>
        </div>
      )}
    </>
  )
}
