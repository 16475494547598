import { Route } from 'react-router-dom'
import { IonRouterOutlet, IonSplitPane } from '@ionic/react'
import { AdminMenu } from './AdminMenu'
import { FC } from 'react'
import { routes } from '../global/routes'
import { ManageDMFeaturePage } from '../pages/ManageDMFeature'
import { AdminHomePage } from '../pages/AdminHomePage'

const pages: {
  href: string
  component: FC
}[] = [
  { href: routes.admin.home, component: AdminHomePage },
  {
    href: routes.admin.dm_feature,
    component: ManageDMFeaturePage,
  },
]

export const AdminPanel = () => {
  let routes = pages.map(page => (
    <Route key={page.href} path={page.href} exact component={page.component} />
  ))
  if ('skip menu') {
    return <>{routes}</>
  }
  return (
    <IonSplitPane contentId="main">
      <AdminMenu />
      <IonRouterOutlet id="main">
        {routes}
        {/*
        <Route path="/admin" exact>
          <Redirect to={routes.admin.home} />
        </Route>
         */}
      </IonRouterOutlet>
    </IonSplitPane>
  )
}
