import {
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from '@ionic/react'
import {
  apertureOutline,
  cartOutline,
  diceOutline,
  ellipsisHorizontalOutline,
  personOutline,
} from 'ionicons/icons'
import React from 'react'
import { Route } from 'react-router-dom'
import { routes } from '../global/routes'
import { MorePage } from '../pages/MorePage'
import { MyProfilePage } from '../pages/MyProfilePage'
import { BoardGameWaitingRoomPage } from '../pages/BoardGameWaitingRoom'
import { ShopHomePage } from '../pages/ShopHomePage'
import { PhotoFeedPage } from '../pages/PhotoFeedPage'

const tabs: {
  tab?: string
  label: string
  icon: string
  href: string
  component: React.FC
}[] = [
  {
    tab: 'my-profile',
    label: 'Profile',
    href: routes.my_profile,
    icon: personOutline,
    component: MyProfilePage,
  },
  {
    tab: 'board-game',
    label: 'Adventure',
    href: routes.board_game.waiting_room,
    icon: diceOutline,
    component: BoardGameWaitingRoomPage,
  },
  {
    tab: 'shop',
    label: 'Shop',
    href: routes.shop.home,
    icon: cartOutline,
    component: ShopHomePage,
  },
  {
    tab: 'photo-feed',
    label: 'DM Diet',
    href: routes.photo_feed.home,
    icon: apertureOutline,
    component: PhotoFeedPage,
  },
  {
    tab: 'more',
    label: 'More',
    href: routes.more,
    icon: ellipsisHorizontalOutline,
    component: MorePage,
  },
]

export const Tabs = () => {
  return (
    <IonTabs>
      <IonRouterOutlet>
        {tabs.map(tab => {
          return (
            <Route
              key={tab.href}
              exact
              path={tab.href}
              component={tab.component}
            />
          )
        })}
      </IonRouterOutlet>
      <IonTabBar slot="bottom">
        {tabs.map(tab => (
          <IonTabButton
            key={tab.href}
            tab={tab.tab || tab.label}
            href={tab.href}
          >
            <IonIcon icon={tab.icon} />
            <IonLabel>{tab.label}</IonLabel>
          </IonTabButton>
        ))}
      </IonTabBar>
    </IonTabs>
  )
}
