import {
  IonButton,
  IonButtons,
  IonChip,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonToolbar,
} from '@ionic/react'
import { useState } from 'react'
import { SupermarketList } from '../components/SupermarketList'
import { SportCenterList } from '../components/SportCenterList'
import { PageTitle } from '../components/PageTitle'
import { useGetAutoRefresh } from '../hooks/use-get'
import { GetShopHome } from 'web-server'
import { refresh } from 'ionicons/icons'
import { wrapToken } from '../components/wrap-token'
import { useSignal } from '../hooks/use-signal'

const tabs = {
  shopping: 'shopping',
  sports: 'sports',
}
export const ShopHomePage = wrapToken(({ token }) => {
  const [tab, setTab] = useState<string>()

  const state = useGetAutoRefresh<typeof GetShopHome>('shop-home', { token })
  const refreshCounter = useSignal(0)

  let coins = undefined
  if (state.state.status === 'ready') {
    coins = state.state.value.coins
  }

  function reload() {
    state.reload()
    refreshCounter(refreshCounter() + 1)
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <PageTitle fontSize="1.1rem">
            Food &amp; Exercise Extravaganza
          </PageTitle>
          <IonButtons slot="end">
            <IonButton onClick={reload}>
              <IonIcon icon={refresh}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar>
          <IonSegment
            value={tab || tabs.shopping}
            onIonChange={e => setTab(e.detail.value)}
          >
            <IonSegmentButton value={tabs.shopping}>
              Supermarket
            </IonSegmentButton>
            <IonSegmentButton value={tabs.sports}>
              Sport Center
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-no-padding">
        {tab === tabs.sports ? (
          <SportCenterList
            refreshCounter={refreshCounter()}
            token={token}
            stats={state.render({
              name: 'User Data',
              render: data => (
                <IonRow className="ion-text-center ">
                  <IonCol>
                    <IonChip color="warning">Coins: {data.coins}</IonChip>
                  </IonCol>
                  <IonCol>
                    <IonChip color="success">
                      Health Points: {data.health_point}
                    </IonChip>
                  </IonCol>
                </IonRow>
              ),
            })}
          />
        ) : (
          <SupermarketList
            user_coins={coins}
            token={token}
            refreshCounter={refreshCounter()}
            stats={state.render({
              name: 'User Data',
              render: data => (
                <IonRow className="ion-text-center ">
                  <IonCol>
                    <IonChip color="warning">Coins: {data.coins}</IonChip>
                  </IonCol>
                  <IonCol>
                    <IonChip color="success">
                      Health Points: {data.health_point}
                    </IonChip>
                  </IonCol>
                </IonRow>
              ),
            })}
          />
        )}
      </IonContent>
    </IonPage>
  )
})
