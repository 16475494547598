import {
  IonButton,
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonThumbnail,
  IonToolbar,
} from '@ionic/react'
import styles from './Shop.module.scss'
import { postAPI, toImageUrl } from '../global/api'
import {
  informationCircleOutline,
  logoApple,
  cashOutline,
  exitOutline,
} from 'ionicons/icons'
import { binArray } from '@beenotung/tslib/array'
import { PageTitle } from './PageTitle'
import {
  GetSportCenter,
  GetSportDetail,
  PostSportCart,
  PostSportCartCancel,
} from 'web-server'
import { useGet, useGetAutoRefresh } from '../hooks/use-get'
import { useObject } from '../hooks/use-object'
import { ErrorMessage } from './ErrorMessage'
import { routes } from '../global/routes'
import { useEffect, useRef, useState } from 'react'
import { Tips } from './Tips'
import { IonModalWrapper } from './IonModalWrapper'

const SportDetailModalBody = (props: { item_id: number }) => {
  const state = useGet<typeof GetSportDetail>('sport-detail', {
    id: props.item_id,
  })
  return (
    <>
      {state.render({
        name: 'Exercise Detail',
        render: data => (
          <>
            <IonThumbnail
              className={styles.thumbnail + ' ' + styles.thumbnailInModal}
            >
              <img
                src={toImageUrl(data.item.image_url)}
                alt={'illustrate of ' + data.item.name}
              ></img>
            </IonThumbnail>
            <IonList>
              <IonItem>
                <IonIcon
                  color="primary"
                  icon={logoApple}
                  slot="start"
                ></IonIcon>
                <IonLabel>{data.item.name}</IonLabel>
              </IonItem>

              <IonItem>
                <IonIcon
                  color="warning"
                  icon={cashOutline}
                  slot="start"
                ></IonIcon>
                <IonLabel>reward {data.item.health_point} coins</IonLabel>
              </IonItem>
              <IonItem hidden={!data.item.desc} className="ion-margin-bottom">
                <IonIcon
                  color="tertiary"
                  icon={informationCircleOutline}
                  slot="start"
                ></IonIcon>
                <p className="ion-text-wrap">
                  {data.item.desc || 'No description available yet'}
                </p>
              </IonItem>
            </IonList>
            <div style={{ height: '5vh' }}></div>
          </>
        ),
      })}
    </>
  )
}

export const SportCenterList = (props: {
  token: string
  refreshCounter: number
  stats: JSX.Element | null
}) => {
  const { token, refreshCounter } = props
  const sportCenter = useGetAutoRefresh<typeof GetSportCenter>('sport-center', {
    token,
  })
  const dirty = useRef(
    new Set<typeof GetSportCenter['output']['cart_sport_list'][number]>(),
  )
  useEffect(() => {
    sportCenter.reload()
  }, [refreshCounter])
  const state = useObject({
    width: 0,
    detail_item_id: 0,
    choose_sport_error: '',
  })
  const chooseSport = (input: {
    sport_id: number
    time_per_week: number
    hour_per_exercise: number
  }) => {
    postAPI<typeof PostSportCart>('sport-cart', {
      token,
      sport_id: input.sport_id,
      hour_per_exercise: input.hour_per_exercise,
      time_per_week: input.time_per_week,
    }).then(output => {
      state.set('choose_sport_error', output.error)
      if (output.error) return
      if (sportCenter.state.status !== 'ready') {
        sportCenter.reload()
        return
      }
      let list = sportCenter.state.value.cart_sport_list
      let idx = list.findIndex(item => item.sport_id == input.sport_id)
      if (idx == -1) {
        list = [...list, input]
      } else {
        list = [...list]
        list[idx] = input
      }
      sportCenter.setState({
        status: 'ready',
        value: {
          cart_sport_list: list,
          sport_list: sportCenter.state.value.sport_list,
        },
      })
    })
  }
  const unChooseSport = (sport_id: number) => {
    postAPI<typeof PostSportCartCancel>('sport-cart-cancel', {
      token,
      sport_id,
    }).then(output => {
      state.set('choose_sport_error', output.error)
      if (output.error) return
      if (sportCenter.state.status !== 'ready') {
        sportCenter.reload()
        return
      }
      let list = sportCenter.state.value.cart_sport_list.filter(
        item => item.sport_id !== sport_id,
      )
      sportCenter.setState({
        status: 'ready',
        value: {
          cart_sport_list: list,
          sport_list: sportCenter.state.value.sport_list,
        },
      })
    })
  }

  return (
    <>
      <IonModalWrapper
        isOpen={!!state.value.detail_item_id}
        showBackdrop
        swipeToClose
        onDidDismiss={() => state.set('detail_item_id', 0)}
      >
        <IonHeader>
          <IonToolbar color="primary">
            <PageTitle>Sports Detail</PageTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => state.set('detail_item_id', 0)}>
                Close
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {state.value.detail_item_id ? (
            <SportDetailModalBody item_id={state.value.detail_item_id} />
          ) : null}
        </IonContent>
      </IonModalWrapper>

      <Tips
        text={
          <>
            Enjoy the physical activities that you prefer. Your choices earn you
            extra <IonIcon color="primary" icon={cashOutline} />
          </>
        }
      />

      {props.stats}

      {sportCenter.render({
        name: 'Exercises',
        render: data => {
          let n_sport_cart = new Set(
            data.cart_sport_list.map(item => item.sport_id),
          ).size
          return (
            <>
              (selected: {n_sport_cart} sports)
              <IonList>
                {binArray(data.sport_list, 2).map(sport_list => (
                  <IonRow key={sport_list[0].id}>
                    {sport_list.map(sport => (
                      <IonCol key={sport.id} size="6">
                        <IonCard
                          key={sport.id}
                          className="ion-no-margin ion-margin-bottom p-2"
                        >
                          <IonThumbnail className={styles.thumbnail}>
                            <img
                              ref={e => {
                                if (!e) return
                                let rect = e.getBoundingClientRect()
                                if (rect.width < state.value.width) return
                                state.set('width', rect.width)
                              }}
                              onLoad={e => {
                                let rect = (
                                  e.target as HTMLImageElement
                                ).getBoundingClientRect()
                                if (rect.width < state.value.width) return
                                state.set('width', rect.width)
                              }}
                              style={{
                                minHeight: state.value.width + 'px',
                                maxHeight: state.value.width + 'px',
                              }}
                              src={toImageUrl(sport.image_url)}
                              className={styles.coverImage}
                              alt={'illustrate of ' + sport.name}
                            ></img>
                          </IonThumbnail>
                          <div
                            className={styles.coverImageContainer}
                            style={{
                              width: state.value.width + 'px',
                              height: state.value.width + 'px',
                              backgroundImage:
                                'url(' + toImageUrl(sport.image_url) + ')',
                            }}
                          ></div>
                          <b>{sport.name}</b>
                          <div>
                            reward {sport.reward}{' '}
                            <IonIcon
                              color="warning"
                              icon={cashOutline}
                            ></IonIcon>{' '}
                          </div>
                          <IonButton
                            hidden
                            color="tertiary"
                            fill="outline"
                            size="small"
                            expand="block"
                            onClick={() =>
                              state.set('detail_item_id', sport.id)
                            }
                          >
                            Details
                          </IonButton>
                          {(function () {
                            const item = data.cart_sport_list.find(
                              item => item.sport_id === sport.id,
                            )
                            if (!item) {
                              return (
                                <IonButton
                                  fill="outline"
                                  size="small"
                                  expand="block"
                                  onClick={() => {
                                    chooseSport({
                                      sport_id: sport.id,
                                      hour_per_exercise: 1,
                                      time_per_week: 1,
                                    })
                                  }}
                                >
                                  Choose
                                </IonButton>
                              )
                            }
                            const updateItem = () => {
                              chooseSport({
                                sport_id: sport.id,
                                hour_per_exercise: item.hour_per_exercise,
                                time_per_week: item.time_per_week,
                              })
                            }
                            return (
                              <>
                                <IonItem>
                                  <IonLabel position="stacked">
                                    Times per week
                                  </IonLabel>
                                  <IonInput
                                    min="1"
                                    max="7"
                                    step="1"
                                    type="number"
                                    value={item.time_per_week || 1}
                                    onIonChange={e => {
                                      let value =
                                        +e.detail.value! ||
                                        item.time_per_week ||
                                        1
                                      item.time_per_week = value
                                      dirty.current.add(item)
                                      sportCenter.rerender()
                                    }}
                                  ></IonInput>
                                </IonItem>
                                <IonItem>
                                  <IonLabel position="stacked">
                                    Hours per exercise
                                  </IonLabel>
                                  <IonInput
                                    min="0.5"
                                    max="8"
                                    type="number"
                                    step="0.5"
                                    value={item.hour_per_exercise || 0.5}
                                    onIonChange={e => {
                                      let value =
                                        +e.detail.value! ||
                                        item.hour_per_exercise ||
                                        0.5
                                      item.hour_per_exercise = value
                                      dirty.current.add(item)
                                      sportCenter.rerender()
                                    }}
                                  ></IonInput>
                                </IonItem>
                                <IonButton
                                  fill="outline"
                                  size="small"
                                  expand="block"
                                  color="primary"
                                  onClick={updateItem}
                                  disabled={!dirty.current.has(item)}
                                >
                                  Update
                                </IonButton>
                                <IonButton
                                  className="ion-margin-top"
                                  fill="outline"
                                  size="small"
                                  expand="block"
                                  color="dark"
                                  onClick={() => {
                                    unChooseSport(sport.id)
                                  }}
                                >
                                  UnChoose
                                </IonButton>
                              </>
                            )
                          })()}
                          <ErrorMessage
                            error={state.value.choose_sport_error}
                          />
                        </IonCard>
                      </IonCol>
                    ))}
                  </IonRow>
                ))}
              </IonList>
              <IonFab slot="fixed" horizontal="end" vertical="bottom">
                <IonFabButton
                  routerLink={routes.shop.sport_center_checkout}
                  disabled={
                    n_sport_cart < 3 ||
                    n_sport_cart > 5 ||
                    sportCenter
                      .getValue()
                      ?.cart_sport_list.some(item => dirty.current.has(item))
                  }
                >
                  {n_sport_cart < 3 || n_sport_cart > 5 ? (
                    <>{n_sport_cart}/5</>
                  ) : (
                    <IonIcon icon={exitOutline}></IonIcon>
                  )}
                </IonFabButton>
              </IonFab>
            </>
          )
        },
      })}
    </>
  )
}
