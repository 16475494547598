import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
  IonBackButton,
  IonSegmentButton,
  IonSegment,
} from '@ionic/react'
import { useMemo, useState } from 'react'
import { routes } from '../global/routes'
import styles from './RankingPage.module.scss'
import { useGet } from '../hooks/use-get'
import {
  GetLevel1Ranking,
  GetLevel2Ranking,
  GetLevel3Ranking,
} from 'web-server'
import { HOUR } from '@beenotung/tslib/time'
import { assets } from '../assets'

function useLastWeek() {
  let date = new Date()
  return useMemo(() => {
    date.setHours(0, 0, 0, 0)
    while (date.getDay() > 0) {
      date.setTime(date.getTime() - 24 * HOUR)
    }
    return date.toISOString()
  }, [date.toDateString()])
}

function renderTable<T extends { id: number }>(props: {
  headers: string[]
  json: { list: T[] }
  renderRow: (row: T) => any
}) {
  let list = props.json.list
  return (
    <>
      <img
        src={assets.ranking}
        className="ion-margin-bottom"
        hidden={list.length === 0}
      />
      <table className={styles.rankTable}>
        <thead>
          {props.headers.map(header => (
            <th key={header}>{header}</th>
          ))}
        </thead>
        <tbody>
          {list.length === 0 ? (
            <tr>
              <td colSpan={2}>
                No data yet, you can get into the top 10 ranking!
              </td>
            </tr>
          ) : (
            list.map(row => <tr key={row.id}>{props.renderRow(row)}</tr>)
          )}
        </tbody>
      </table>
    </>
  )
}

function Level1() {
  const ranking = useGet<typeof GetLevel1Ranking>('level-1-ranking', {
    last_week: useLastWeek(),
  })
  return ranking.render({
    name: 'Level 1 Ranking',
    render: json =>
      renderTable({
        headers: ['Nickname', 'Moves'],
        json,
        renderRow: row => (
          <>
            <td>{row.nickname}</td>
            <td>{row.total_move}</td>
          </>
        ),
      }),
  })
}
function Level2() {
  const ranking = useGet<typeof GetLevel2Ranking>('level-2-ranking', {
    last_week: useLastWeek(),
  })
  return ranking.render({
    name: 'Level 2 Ranking',
    render: json =>
      renderTable({
        headers: ['Nickname', 'Healthy Points'],
        json,
        renderRow: row => (
          <>
            <td>{row.nickname}</td>
            <td>{row.total_correct_answer}</td>
          </>
        ),
      }),
  })
}
function Level3() {
  const ranking = useGet<typeof GetLevel3Ranking>('level-3-ranking', {
    last_week: useLastWeek(),
  })
  return ranking.render({
    name: 'Level 3 Ranking',
    render: json =>
      renderTable({
        headers: ['Nickname', 'Received Stars'],
        json,
        renderRow: row => (
          <>
            <td>{row.nickname}</td>
            <td>{row.total_stars}</td>
          </>
        ),
      }),
  })
}

export const RankingPage = () => {
  const [level, setLevel] = useState('1')
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton defaultHref={routes.more} />
          </IonButtons>
          <IonTitle>Weekly Ranking</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonSegment
          value={level}
          onIonChange={e => setLevel(e.detail.value || level)}
        >
          <IonSegmentButton value="1">Level 1</IonSegmentButton>
          <IonSegmentButton value="2">Level 2</IonSegmentButton>
          <IonSegmentButton value="3">Level 3</IonSegmentButton>
        </IonSegment>

        <div className="ion-text-center ion-padding">
          {
            {
              1: <Level1 />,
              2: <Level2 />,
              3: <Level3 />,
            }[level]
          }
        </div>
      </IonContent>
    </IonPage>
  )
}
