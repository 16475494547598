import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
  IonBackButton,
  IonRow,
  IonCol,
  IonButton,
  IonText,
  IonAvatar,
  IonSegment,
  IonSegmentButton,
  IonIcon,
  IonItem,
  IonList,
  IonModal,
  IonNote,
} from '@ionic/react'
import { toAvatarUrl } from '../global/api'
import styles from './MyProfilePage.module.scss'
import { useRouteMatch } from 'react-router-dom'
import { Code } from '../components/Code'
import { useState } from 'react'
import { useGetAutoRefresh } from '../hooks/use-get'
import type {
  GetMyBadge,
  GetMyMenuRecord,
  GetMyProfile,
  GetMyShoppingRecord,
  GetMySportRecord,
} from 'web-server'
import { assets } from '../assets/index'
import { routes } from '../global/routes'
import { refresh } from 'ionicons/icons'
import { format_datetime } from '@beenotung/tslib/format'
import { wrapToken } from '../components/wrap-token'
import { useSignal } from '../hooks/use-signal'
import { capitalize } from '@beenotung/tslib/string'

function Badge(props: {
  name: keyof typeof assets.badge
  condition: string
  unlock: boolean
  size?: 'big'
  modalTitle?: string
  children?: any
  modalNoPadding?: boolean
}) {
  const img = assets.badge[props.name]
  const isOpen = useSignal(false)
  const title = props.modalTitle || capitalize(props.name) + ' Badge'
  return (
    <div
      className={
        styles.badgeContainer +
        ' ' +
        (props.size === 'big' ? styles.bigBadgeContainer : '')
      }
      title={title}
    >
      <IonModal isOpen={isOpen()}>
        <IonHeader>
          <IonToolbar color="primary">
            <IonTitle>{title}</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => isOpen(false)}>Close</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className={props.modalNoPadding ? '' : 'ion-padding'}>
          <div className="ion-text-center" hidden={!!props.children}>
            <img
              className={
                styles.badge + ' ' + (props.unlock ? '' : styles.badgeLocked)
              }
              src={img}
              alt={`badge of ${props.condition}`}
            />
            <div>
              <b>({props.unlock ? '' : 'Not '}Unlocked)</b>
            </div>
            <p>Unlock by {props.condition}</p>
          </div>
          {props.children}
        </IonContent>
      </IonModal>
      <img
        className={
          styles.badge +
          ' ' +
          (props.unlock ? '' : styles.badgeLocked) +
          ' ' +
          (props.size === 'big' ? styles.bigBadge : '')
        }
        src={img}
        alt={`badge of ${props.condition}`}
        onClick={() => isOpen(true)}
      />
      <div hidden className={styles.badgeText}>
        {props.condition}
      </div>
    </div>
  )
}

function CompletionCert(props: { name: string; time: number }) {
  return (
    <div className={'ion-text-center ' + styles.certContainer}>
      <div className={styles.certInnerContainer}>
        <div className={styles.certIconContainer}>
          <img src={assets.badge.cert} />
        </div>
        <div style={{ fontSize: 'x-large', fontWeight: 'bold' }}>
          Congratulations
        </div>
        <div style={{ color: '#888', margin: '0.5rem 0' }}>
          This certificate is awarded to
        </div>
        <div style={{ margin: '0.5rem 0', fontSize: 'larger' }}>
          {props.name}
        </div>
        <div style={{ color: '#888', margin: '0.5rem 0' }}>
          for successful completion of
        </div>
        <div style={{ fontSize: 'larger', fontWeight: 'bold' }}>
          Diabetes Mellitus Master
        </div>
        <div style={{ margin: '0.5rem 0' }}>
          on {new Date(props.time).toLocaleString()}
        </div>
        <div className={styles.certLogoContainer}>
          <img className={styles.certLogo} src={assets.logo_alpha} />
        </div>
      </div>
    </div>
  )
}

type TabProps = {
  token: string
}

const BadgeTab = ({ token, name }: TabProps & { name: string }) => {
  const myBadge = useGetAutoRefresh<typeof GetMyBadge>('my-badge', {
    token,
  })

  return (
    <>
      <div className="ion-text-center">
        <IonNote>Click on the badges to see how to unlock</IonNote>
      </div>
      <div className="ion-margin-top">
        {myBadge.render({
          name: 'Badge List',
          render: ({ badge }) => (
            <>
              <div
                className={styles.badgeGroup}
                title="Game Completion Certification"
              >
                <Badge
                  name="cert"
                  condition="completing all 3 levels and collecting all badges"
                  unlock={badge.cert > 0}
                  size="big"
                  modalTitle="Completion Cert"
                  modalNoPadding={badge.cert > 0}
                >
                  {badge.cert > 0 ? (
                    <CompletionCert name={name} time={badge.cert} />
                  ) : null}
                </Badge>
              </div>
              <div className={styles.badgeGroup} title="Level 1 Badges">
                <Badge
                  name="crown"
                  condition="hosting a Sweet Adventure"
                  unlock={badge.chess_host > 0}
                />
                <Badge
                  name="wing"
                  condition="joining a Sweet Adventure"
                  unlock={badge.chess_join > 0}
                />
                <Badge
                  name="goal"
                  condition="reaching the goal in Sweet Adventure"
                  unlock={badge.chess_goal > 0}
                />
              </div>
              <div className={styles.badgeGroup} title="Level 2 Badges">
                <Badge
                  name="wheat"
                  condition="shopping in supermarket"
                  unlock={badge.shopping > 0}
                />
                <Badge
                  name="sport"
                  condition="doing sport in health center"
                  unlock={badge.sport > 0}
                />
              </div>
              <div className={styles.badgeGroup} title="Level 3 Badges">
                <Badge
                  name="jewel"
                  condition="sharing featured diabetes management menu"
                  unlock={badge.menu > 0}
                />
                <Badge
                  name="star"
                  condition="receiving rating from your featured diabetes management menu"
                  unlock={badge.menu_rating_received > 0}
                />
                <Badge
                  name="flower"
                  condition="giving rating to featured diabetes management menu to your peers"
                  unlock={badge.menu_rating_given > 0}
                />
              </div>
            </>
          ),
        })}

        <img
          hidden
          src={assets.badge.all}
          alt="placeholder of award badge"
        ></img>
      </div>
    </>
  )
}
const ShoppingTab = ({ token }: TabProps) => {
  const record = useGetAutoRefresh<typeof GetMyShoppingRecord>(
    'my-shopping-record',
    {
      token,
    },
  )
  return (
    <div className="ion-padding-horizontal">
      {record.render({
        name: 'My Shopping Record',
        render: data => (
          <IonList>
            {data.purchase_list.length === 0 ? (
              <p>No purchase records yet</p>
            ) : (
              data.purchase_list.map(purchase => (
                <IonItem key={purchase.id}>
                  <div className="w-100">
                    <div className="ion-text-start">
                      {format_datetime(new Date(purchase.timestamp).getTime())}
                    </div>
                    <div className="ion-text-end">
                      {purchase.total_quantity} items
                    </div>
                  </div>
                </IonItem>
              ))
            )}
          </IonList>
        ),
      })}
    </div>
  )
}
const SportTab = ({ token }: TabProps) => {
  const record = useGetAutoRefresh<typeof GetMySportRecord>('my-sport-record', {
    token,
  })
  return (
    <div className="ion-padding-horizontal">
      {record.render({
        name: 'My Exercises Record',
        render: data => (
          <IonList>
            {data.sport_list.length === 0 ? (
              <p>No exercise records yet</p>
            ) : (
              data.sport_list.map(sport => (
                <IonItem key={sport.id}>
                  <div className="w-100">
                    <div className="ion-text-start">
                      {format_datetime(new Date(sport.timestamp).getTime())}
                    </div>
                    <div className="ion-text-end">{sport.name}</div>
                    <div className="ion-text-end" hidden={!sport.time_per_week}>
                      {sport.time_per_week} times per week
                    </div>
                    <div
                      className="ion-text-end"
                      hidden={!sport.hour_per_exercise}
                    >
                      {sport.hour_per_exercise} hours per exercise
                    </div>
                  </div>
                </IonItem>
              ))
            )}
          </IonList>
        ),
      })}
    </div>
  )
}
const DietTab = ({ token }: TabProps) => {
  const record = useGetAutoRefresh<typeof GetMyMenuRecord>('my-menu-record', {
    token,
  })
  return (
    <div className="ion-padding-horizontal">
      {record.render({
        name: 'My Exercises Record',
        render: data => (
          <IonList>
            {data.menu_list.length === 0 ? (
              <p>No menu records yet</p>
            ) : (
              data.menu_list.map(menu => (
                <IonItem key={menu.id}>
                  <div className="w-100">
                    <div className="ion-text-start">
                      {format_datetime(new Date(menu.timestamp).getTime())}
                    </div>
                    <div className="ion-text-end">{menu.meal}</div>
                  </div>
                </IonItem>
              ))
            )}
          </IonList>
        ),
      })}
    </div>
  )
}

const GuidelineTab = () => {
  return (
    <div className="ion-padding-horizontal">
      <p>Welcome! :D</p>
      <p>
        This is an interactive education tool to support you to learn more about
        Type 2 diabetes from a health professional perspective. The game has
        three levels:
      </p>
      <p>
        Level 1. Sweet Adventure
        <br />
        Level 2. Food &amp; Exercise Extravaganza
        <br />
        Level 3. Featured DM Diet
      </p>
      <p>
        You will also explore the goals and strategies of diabetes management,
        including healthy lifestyles and medications. Start now and learn with
        great fun!
      </p>
    </div>
  )
}

const tabs = {
  badge: 'badge',
  shopping: 'shopping',
  sports: 'sports',
  diet: 'diet',
}

export const MyProfilePage = wrapToken(({ token }) => {
  const route = useRouteMatch()
  const [tab, setTab] = useState<string>()
  const profile = useGetAutoRefresh<typeof GetMyProfile>('my-profile', {
    token,
  })
  const name = profile.getValue()?.profile.nickname || 'Current Player'
  const textFields: (keyof typeof GetMyProfile['output']['profile'])[] = [
    'bio',
    // 'diet_habit',
    // 'sport_habit',
  ]
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>My Profile</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => profile.reload()}>
              <IonIcon icon={refresh}></IonIcon>
            </IonButton>
            <IonBackButton />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {profile.render({
          name: 'My Profile',
          render: data => (
            <IonRow className="ion-padding-horizontal ion-padding-top">
              <IonCol size="auto">
                <IonAvatar className={styles.avatar}>
                  <img
                    src={toAvatarUrl(
                      data.profile.image_url,
                      data.profile.nickname || data.profile.id,
                    )}
                    alt="User Avatar"
                  ></img>
                </IonAvatar>
              </IonCol>
              <IonCol>
                <div className={styles.nickname}>
                  <IonText color="primary">
                    <b>{data.profile.nickname}</b>
                  </IonText>
                </div>
                {textFields
                  .map(field => [field, data.profile[field]])
                  .filter(([field, text]) => text)
                  .map(([field, text]) => (
                    <p key={field}>{text}</p>
                  ))}
                <div className="ion-text-center">
                  <IonButton
                    fill="outline"
                    size="small"
                    routerLink={routes.edit_profile}
                  >
                    Edit Profile
                  </IonButton>
                </div>
              </IonCol>
            </IonRow>
          ),
        })}

        <h2 className="ion-padding-horizontal">Record</h2>
        <IonSegment value={tab} onIonChange={e => setTab(e.detail.value)}>
          <IonSegmentButton value={tabs.badge}>Badge</IonSegmentButton>
          <IonSegmentButton value={tabs.shopping}>Shopping</IonSegmentButton>
          <IonSegmentButton value={tabs.sports}>Sports</IonSegmentButton>
          <IonSegmentButton value={tabs.diet}>Diet</IonSegmentButton>
        </IonSegment>

        {(() => {
          switch (tab) {
            case undefined:
              return <GuidelineTab />
            case tabs.badge:
              return <BadgeTab token={token} name={name} />
            case tabs.shopping:
              return <ShoppingTab token={token} />
            case tabs.sports:
              return <SportTab token={token} />
            case tabs.diet:
              return <DietTab token={token} />
            default:
              return (
                <>
                  <Code json={{ tab }} />
                </>
              )
          }
        })()}
      </IonContent>
    </IonPage>
  )
})
