import { useIonRouter } from '@ionic/react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useRouteMatch } from 'react-router'
import { ErrorMessage } from '../components/ErrorMessage'
import { LoadingMessage } from '../components/LoadingMessage'
import { callAPI } from '../global/api'

export function useGetAutoRefresh<
  A extends { method: 'get'; name: string; input: object; output: object },
>(name: A['name'], input: A['input']) {
  const got = useGet<A>(name, input)
  const ref = useRef({ isFirst: true })

  const route = useRouteMatch()
  const router = useIonRouter()
  const isRouteMatch =
    route.path === router.routeInfo.pathname ||
    route.url === router.routeInfo.pathname

  const reload = got.reload

  useEffect(() => {
    if (!isRouteMatch) return
    if (ref.current.isFirst) {
      ref.current.isFirst = false
      return
    }
    reload()
  }, [isRouteMatch, ref, reload])

  return got
}

export function useGet<
  A extends { method: 'get'; name: string; input: object; output: object },
>(name: A['name'], input: A['input'] | 'skip') {
  type State =
    | {
        status: 'loading'
      }
    | {
        status: 'ready'
        value: A['output']
      }
    | {
        status: 'error'
        error: string
      }
  const [state, setState] = useState<State>({ status: 'loading' })

  const inputJSON = JSON.stringify(input)
  const download = useCallback(() => {
    if (input == 'skip') {
      return
    }
    callAPI<A>('get', name, input).then(output => {
      let error = (output as any).error
      if (error) {
        setState({ status: 'error', error })
      } else {
        setState({ status: 'ready', value: output })
      }
    })
  }, [name, inputJSON])

  useEffect(() => {
    if (state.status != 'loading') {
      setState({ status: 'loading' })
    }
    download()
  }, [download])

  const render = (props: {
    name?: string
    render: (value: A['output']) => JSX.Element
  }) => {
    switch (state.status) {
      case 'ready':
        return props.render(state.value)
      case 'error':
        return props.name ? <ErrorMessage error={state.error} /> : null
      case 'loading':
        return props.name ? <LoadingMessage name={props.name} /> : null
    }
  }

  const rerender = () => {
    setState(state => ({ ...state }))
  }

  const getValue = () => (state.status === 'ready' ? state.value : undefined)

  return {
    state,
    getValue,
    render,
    reload: download,
    rerender,
    setState,
  }
}
