import { useEffect } from 'react'

export function useTimeout(cb: () => void, ms: number) {
  useEffect(() => {
    const timer = setTimeout(cb, ms)
    return () => {
      clearTimeout(timer)
    }
  }, [cb, ms])
}
