import { IonButton, IonButtons, IonIcon } from '@ionic/react'
import { star, starOutline } from 'ionicons/icons'

export function Rating(props: {
  inline?: boolean
  value: number | undefined
  onChange: (rating: number) => void
}) {
  const { value, inline } = props
  const body = (
    <IonButtons>
      {new Array(5).fill(0).map((_, i) => (
        <IonButton
          key={i}
          color={value && i >= value ? 'medium' : 'primary'}
          onClick={() => props.onChange(i + 1)}
        >
          <IonIcon
            icon={!value ? starOutline : i < value ? star : starOutline}
          ></IonIcon>
        </IonButton>
      ))}
    </IonButtons>
  )
  return (
    <>
      {inline ? (
        body
      ) : (
        <div className="d-flex ion-justify-content-center">{body}</div>
      )}
    </>
  )
}
