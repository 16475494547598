// output format sample as md5 (32 hex char in lower case) (128 bit)
export function simpleHash(input: string): string {
  let acc = BigInt(1)
  let ten = BigInt(100)
  for (let char of input) {
    let c = BigInt(char.charCodeAt(0))
    acc = acc * ten + c
    while (acc.toString(16).length < 32) {
      acc = acc * acc
    }
    while (acc.toString(16).length >= 32) {
      acc = acc / ten
    }
  }
  let hash = acc.toString(16)
  hash = hash.slice(0, 32)
  hash = '0'.repeat(32 - hash.length) + hash
  // console.log({ input, hash })
  return hash
}

// console.log(simpleHash('bot'))

// for (let i = 1; i < 35; i++) {
//   let h = simpleHash(String(i))
//   console.log(i, h)
// }
