export const AboutLevel2 = () => {
  return (
    <>
      <h2>Level 2: Food and Exercise Extravaganza</h2>
      <p>
        <b>Supermarket:</b>
        Buy goodies in the supermarket and pay at the cashier. After checkout -
        Benchmark your purchasing items against the nutrition principles for
        diabetes.
      </p>
      <p>
        <b>Super Health Center:</b> Enjoy the physical activities that you
        prefer.
      </p>
    </>
  )
}

export let ToLevel3Message = () => {
  return (
    <>
      <p>
        Exit - Benchmark your selected exercises against the recommendations of
        the American Diabetes Association (ADA).
      </p>
      <p>
        Your healthy food choice and physical fitness have earned you a health
        report.
      </p>
    </>
  )
}
