import { SECOND } from '@beenotung/tslib/time'
import {
  IonButton,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonNote,
  IonRadio,
  IonRadioGroup,
  IonText,
  IonToolbar,
  useIonRouter,
} from '@ionic/react'
import { useEffect, useLayoutEffect } from 'react'
import {
  GetShopQuestionList,
  GetSportQuestionList,
  PostShopAnswer,
  PostSportAnswer,
} from 'web-server'
import { ErrorMessage } from '../components/ErrorMessage'
import { LoadingMessage } from '../components/LoadingMessage'
import { PageTitle } from '../components/PageTitle'
import { callAPI, postAPI } from '../global/api'
import { useCountDown } from '../hooks/use-count-down'
import { useObject } from '../hooks/use-object'
import sweetAlert from 'sweetalert2'
import { routes } from '../global/routes'
import useStorageState from 'react-use-storage-state'
import { IonModalWrapper } from '../components/IonModalWrapper'
import { config } from '../config'

const shop_titles = {
  yes_no_question_title: 'My Food Choice is Healthy because:',
  true_false_question_title:
    'I select my food based on the following principles:',
}
const sport_titles = {
  yes_no_question_title: 'My Sport Choice is Healthy because:',
  true_false_question_title:
    'I select my physical activities based on the following principles:',
}

type GetQuestionListResult = typeof GetShopQuestionList['output']

const QUESTION_TIMEOUT = 90 * SECOND

const ShopQuestionModal2 = (
  props: {
    token: string
    is_finish_shop: boolean
  } & (
    | {
        purchase_id: number
        sport_batch_id: undefined
      }
    | {
        purchase_id: undefined
        sport_batch_id: number
      }
  ),
) => {
  const router = useIonRouter()
  const state = useObject({
    isOpen: false,
    isClosed: false,
    question_index: 0,
    startTime: 0,
    endTime: 0,
    questionList: {
      purchase_id: 0,
      sport_batch_id: 0,
      output: null as null | GetQuestionListResult,
    },
    submitResult: { error: '', is_correct: null as null | boolean },
    nCorrect: 0,
    nWrong: 0,
  })
  const { token, purchase_id, sport_batch_id } = props
  const {
    isOpen,
    isClosed,
    questionList,
    submitResult,
    startTime,
    endTime,
    question_index,
    nCorrect,
    nWrong,
  } = state.value
  const nTotal = nCorrect + nWrong
  const nCorrectPercent = nTotal > 0 ? (nCorrect / nTotal) * 100 : 0
  const hasAnswer = submitResult.is_correct !== null
  const { left: leftTime, leftText } = useCountDown(endTime)
  useLayoutEffect(() => {
    if (!isOpen && !isClosed) {
      let timer = setTimeout(() => {
        state.set('isOpen', true)
      }, 1000)
      return () => clearTimeout(timer)
    }
  }, [isOpen, isClosed])
  const [have_seen_level_2_finish, set_have_seen_level_2_finish] =
    useStorageState('have_seen_level_2_finish', false)
  function closeModal() {
    state.set('isOpen', false)
    state.set('isClosed', true)
    if (
      props.is_finish_shop &&
      (!have_seen_level_2_finish || config.showFinishLevelEveryTime)
    ) {
      set_have_seen_level_2_finish(true)
      router.push(routes.shop.finish_level2)
      return
    }
    if (router.canGoBack()) {
      router.goBack()
    } else {
      router.push(routes.shop.home, 'back')
    }
  }
  useEffect(() => {
    if (purchase_id) {
      if (purchase_id !== questionList.purchase_id || !questionList.output) {
        state.set('isOpen', true)
        let ended = false
        callAPI<typeof GetShopQuestionList>('get', 'shop-question-list', {
          purchase_id,
          token,
        }).then(output => {
          if (ended) return
          state.set('questionList', { purchase_id, output, sport_batch_id: 0 })
        })
        return () => {
          ended = true
        }
      }
    }
    if (sport_batch_id) {
      if (
        sport_batch_id !== questionList.sport_batch_id ||
        !questionList.output
      ) {
        state.set('isOpen', true)
        let ended = false
        callAPI<typeof GetSportQuestionList>('get', 'sport-question-list', {
          sport_batch_id,
          token,
        }).then(output => {
          if (ended) return
          state.set('questionList', { purchase_id: 0, sport_batch_id, output })
        })
        return () => {
          ended = true
        }
      }
    }
  }, [purchase_id, sport_batch_id, questionList, token])
  function submit(options: { question_id: number; answer: string }) {
    if (hasAnswer) return
    if (purchase_id) {
      postAPI<typeof PostShopAnswer>('shop-answer', {
        token,
        question_id: options.question_id,
        answer: options.answer,
        used_time: Date.now() - startTime,
        purchase_id,
      }).then(output => {
        state.set('submitResult', output)
      })
    }
    if (sport_batch_id) {
      postAPI<typeof PostSportAnswer>('sport-answer', {
        token,
        question_id: options.question_id,
        answer: options.answer,
        used_time: Date.now() - startTime,
        sport_batch_id,
      }).then(output => {
        state.set('submitResult', output)
      })
    }
  }
  useEffect(() => {
    if (submitResult.is_correct === null) return
    if (submitResult.is_correct) {
      sweetAlert.fire('Good job', 'Your answer is correct / healthy', 'success')
      state.update('nCorrect', count => count + 1)
    } else {
      sweetAlert.fire(
        'Keep up',
        'Your answer is not correct / unhealthy',
        'warning',
      )
      state.update('nWrong', count => count + 1)
    }
  }, [submitResult])
  function setQuestionIndex(index: number) {
    state.set('question_index', index)
    let now = Date.now()
    state.set('startTime', now)
    state.set('endTime', now + QUESTION_TIMEOUT)
    state.set('submitResult', { error: '', is_correct: null })
  }
  function render() {
    const output = questionList.output
    if (!output) {
      return { title: '', body: <LoadingMessage name="question list" /> }
    }
    if (output.error) {
      return { title: '', body: <ErrorMessage error={output.error} /> }
    }
    const titles = sport_batch_id ? sport_titles : shop_titles
    const total_n_question =
      output.yes_no_question_list.length +
      output.true_false_question_list.length
    const mode =
      question_index < output.yes_no_question_list.length
        ? 'yes_no'
        : question_index < total_n_question
        ? 'true_false'
        : null
    const question =
      mode === 'yes_no'
        ? output.yes_no_question_list[question_index]
        : mode === 'true_false'
        ? output.true_false_question_list[
            question_index - output.yes_no_question_list.length
          ]
        : null
    const question_title =
      mode === 'yes_no'
        ? titles.yes_no_question_title
        : mode === 'true_false'
        ? titles.true_false_question_title
        : 'Finished Quiz'
    const isLast = question_index + 1 >= total_n_question

    return {
      title: (
        <>
          (
          {question_index + 1 > total_n_question
            ? 'Done'
            : question_index + 1 + '/' + total_n_question}
          )
        </>
      ),
      body: (
        <>
          <IonNote>
            Answer the questions to refresh your DM knowledge and gain healthy
            points!
          </IonNote>
          {question_index === 0 && !startTime ? (
            <IonButton expand="block" onClick={() => setQuestionIndex(0)}>
              Start
            </IonButton>
          ) : question ? (
            <>
              <IonList>
                <h2>{question_title}</h2>
                <IonRadioGroup
                  key={question.id}
                  onIonChange={e =>
                    submit({ question_id: question.id, answer: e.detail.value })
                  }
                >
                  <IonListHeader>
                    <IonLabel>{question.question}</IonLabel>
                  </IonListHeader>
                  {mode === 'yes_no' ? (
                    <>
                      <IonItem>
                        <IonLabel>Yes</IonLabel>
                        <IonRadio disabled={hasAnswer} value="Y"></IonRadio>
                      </IonItem>
                      <IonItem>
                        <IonLabel>No</IonLabel>
                        <IonRadio disabled={hasAnswer} value="N"></IonRadio>
                      </IonItem>
                    </>
                  ) : (
                    <>
                      <IonItem>
                        <IonLabel>Yes</IonLabel>
                        <IonRadio disabled={hasAnswer} value="T"></IonRadio>
                      </IonItem>
                      <IonItem>
                        <IonLabel>No</IonLabel>
                        <IonRadio disabled={hasAnswer} value="F"></IonRadio>
                      </IonItem>
                    </>
                  )}
                </IonRadioGroup>
                {!hasAnswer || !isLast ? (
                  <IonButton
                    expand="block"
                    onClick={() => setQuestionIndex(question_index + 1)}
                  >
                    Next
                    <>{hasAnswer ? null : <> ({leftText})</>}</>
                  </IonButton>
                ) : (
                  <>
                    <p className="ion-text-center">
                      {nCorrectPercent >= 60 ? (
                        <IonText color="success">
                          {nCorrectPercent.toFixed(0)}% Healthy
                          <br />
                          Well Done!
                        </IonText>
                      ) : (
                        <IonText color="danger">
                          {nCorrectPercent.toFixed(0)}% Healthy
                          <br />
                          Keep up!
                        </IonText>
                      )}
                    </p>
                    <IonButton expand="block" onClick={closeModal}>
                      Close
                    </IonButton>
                  </>
                )}

                <ErrorMessage error={submitResult.error} />
              </IonList>
            </>
          ) : (
            <>
              <IonButton expand="block" onClick={closeModal}>
                Close
              </IonButton>
            </>
          )}
        </>
      ),
    }
  }
  const { title, body } = render()
  return (
    <>
      <IonModalWrapper
        isOpen={isOpen}
        swipeToClose={false}
        backdropDismiss={false}
      >
        <IonHeader>
          <IonToolbar color="primary">
            <PageTitle>Popup Quiz {title}</PageTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">{body}</IonContent>
      </IonModalWrapper>
    </>
  )
}
export default ShopQuestionModal2
