import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonContent,
  IonList,
  IonItem,
  IonIcon,
  IonLabel,
  useIonAlert,
} from '@ionic/react'
import {
  analyticsOutline,
  documentTextOutline,
  flaskOutline,
  glassesOutline,
  informationCircleOutline,
  logInOutline,
  statsChartOutline,
} from 'ionicons/icons'
import { PageTitle } from '../components/PageTitle'
import { routes } from '../global/routes'
import { IonButton } from '@ionic/react'
import { useToken } from '../hooks/use-token'
import { postAPI } from '../global/api'
import { PostAccountDelete } from 'web-server'

const pages: {
  label: string
  href: string
  icon?: string
  ios?: string
  md?: string
  hideWhenLogin?: boolean
  hideWhenLogout?: boolean
}[] = [
  {
    label: 'Ranking',
    href: routes.ranking,
    icon: statsChartOutline,
  },
  { label: 'Feedback', href: routes.feedback, icon: flaskOutline },
  { label: 'Term of Usage', href: routes.terms, icon: documentTextOutline },
  { label: 'Privacy', href: routes.privacy, icon: glassesOutline },
  {
    label: 'Game Instruction',
    href: routes.about,
    icon: informationCircleOutline,
  },
  {
    label: 'Analytics',
    href: routes.admin.home,
    icon: analyticsOutline,
  },
  {
    label: 'Login',
    href: routes.login + '?back=more',
    icon: logInOutline,
    hideWhenLogin: true,
  },
]

export const MorePage = () => {
  const [token, setToken] = useToken()
  const [presentAlert] = useIonAlert()
  const logout = () => {
    setToken(null)
    // router.push(routes.home, 'root')
  }
  const deleteAccount = (token: string) => {
    presentAlert({
      header: 'Account Deletion',
      subHeader: 'Do you confirm to delete this account?',
      message: 'You will lose all progress and this account is not reversable.',
      buttons: [
        { text: 'Cancel', role: 'cancel' },
        {
          text: 'Delete Account',
          handler: () => {
            postAPI<typeof PostAccountDelete>('account-delete', { token }).then(
              output => {
                if (output.error) {
                  presentAlert(output.error)
                  return
                }
                logout()
              },
            )
          },
        },
      ],
    })
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <PageTitle>More</PageTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonList>
          {pages.map(page => (
            <IonItem
              key={page.href}
              routerLink={page.href}
              hidden={page.hideWhenLogin && !!token}
            >
              <IonIcon
                slot="start"
                icon={page.icon}
                ios={page.ios}
                md={page.md}
              />
              <IonLabel>{page.label}</IonLabel>
            </IonItem>
          ))}
        </IonList>
        {token ? (
          <IonList className="ion-padding">
            <IonButton expand="block" color="danger" onClick={logout}>
              Logout
            </IonButton>
            <IonButton
              className="ion-margin-top"
              expand="block"
              color="dark"
              onClick={() => deleteAccount(token)}
            >
              Delete Account
            </IonButton>
          </IonList>
        ) : null}
      </IonContent>
    </IonPage>
  )
}
