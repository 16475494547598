import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButton,
  IonButtons,
  IonBackButton,
} from '@ionic/react'
import { useRouteMatch } from 'react-router'
import { routes } from '../global/routes'

export const NotMatchPage = () => {
  let route = useRouteMatch()
  console.log('route not match:', route)
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color='primary'>
          <IonButtons slot="start">
            <IonBackButton/>
          </IonButtons>
          <IonTitle>Page Not Found</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonButton routerLink={routes.home}>Back to Home Page</IonButton>
        <h2>Debug Information:</h2>
        <pre>
          <code>{JSON.stringify(route, null, 2)}</code>
        </pre>
      </IonContent>
    </IonPage>
  )
}
