import { useCallback, useState } from 'react'

export function useSignal<T>(initialValue: T) {
  const [state, setState] = useState(initialValue)
  type Signal = {
    (value: T): void
    (): T
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const signal = useCallback<Signal>(
    function (value?: T) {
      if (arguments.length === 0) {
        return state
      }
      setState(value!)
    } as Signal,
    [state, setState],
  )
  return signal
}
