import { useToken } from '../hooks/use-token'
import { LoginPage } from '../pages/LoginPage'

export const wrapToken = (
  Component: React.FC<{
    token: string
    setToken: (token: string | null) => void
  }>,
) => {
  return () => {
    const [token, setToken] = useToken()
    return (
      <>
        {token ? (
          <Component token={token} setToken={setToken} />
        ) : (
          <LoginPage />
        )}
      </>
    )
  }
}
