import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
  IonAvatar,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCol,
  IonLabel,
  IonList,
  IonListHeader,
  IonRow,
  IonText,
  useIonRouter,
  IonSearchbar,
  IonIcon,
  useIonAlert,
  useIonToast,
  IonModal,
} from '@ionic/react'
import { postAPI, SERVER_ORIGIN, toAvatarUrl } from '../global/api'
import { isIPhone } from '../global/platform'
import { routes } from '../global/routes'
import styles from './BoardGameWaitingRoom.module.scss'
import { useGetAutoRefresh } from '../hooks/use-get'
import {
  GetBoardGameWaitingRoom,
  PostBoardGameRoom,
  PostBoardGameRoomJoin,
  PostBoardGameRoomQuit,
  PostBoardGameRoomStart,
} from 'web-server'
import { IonNote } from '@ionic/react'
import { useObject } from '../hooks/use-object'
import { helpCircle, refresh } from 'ionicons/icons'
import { ToastDuration } from '../global/ui'
import { wrapToken } from '../components/wrap-token'
import { PageTitle } from '../components/PageTitle'
import { AboutLevel1 } from '../components/AboutLevel1'
import { toPlayerNickname } from '../global/format'
import { useSocket } from '../hooks/use-socket'
import useStorageState from 'react-use-storage-state'
import { Tips } from '../components/Tips'

export const BoardGameWaitingRoomPage = wrapToken(({ token }) => {
  const waitingRoom = useGetAutoRefresh<typeof GetBoardGameWaitingRoom>(
    'board-game-waiting-room',
    {
      status: 'waiting',
      token,
    },
  )
  const [have_seen_level_1_help, set_have_seen_level_1_help] = useStorageState(
    'have_seen_level_1_help',
    false,
  )
  const state = useObject({
    room_name: '',
    create_room_error: '',
    showRules: !have_seen_level_1_help,
  })

  const router = useIonRouter()
  const ionAlert = useIonAlert()
  const ionToast = useIonToast()

  const { reconnect } = useSocket(SERVER_ORIGIN, socket => {
    socket.emit('join-waiting-room')
    socket.on('waiting-room-update', waitingRoom.reload)
  })

  function reload() {
    waitingRoom.reload()
    reconnect()
  }

  function closeRules() {
    state.set('showRules', false)
    set_have_seen_level_1_help(true)
  }

  const canCreateRoom = (): boolean => {
    if (!state.value.room_name) return false
    if (waitingRoom.state.status !== 'ready') return false
    return !waitingRoom.state.value.current_room_id
  }

  function createRoom() {
    if (!state.value.room_name) {
      state.set('create_room_error', 'Please enter room name first.')
      return
    }
    postAPI<typeof PostBoardGameRoom>('board-game-room', {
      token,
      room_name: state.value.room_name,
    }).then(output => {
      state.set('create_room_error', output.error)
      if (!output.error) {
        ionToast[0]({
          message: 'Created room',
          duration: ToastDuration.Normal,
        })
        state.set('room_name', '')
        waitingRoom.reload()
      }
    })
  }
  function joinRoom(room_id: number) {
    postAPI<typeof PostBoardGameRoomJoin>('board-game-room/join', {
      token,
      room_id,
    }).then(output => {
      if (output.error) {
        ionAlert[0]({
          header: 'Failed to join room',
          message: output.error,
          buttons: [{ text: 'Dismiss', role: 'cancel' }],
        })
        return
      }
      ionToast[0]({
        message: 'Joined room',
        duration: ToastDuration.Short,
      })
      waitingRoom.reload()
    })
  }
  function leaveRoom(room_id: number) {
    postAPI<typeof PostBoardGameRoomQuit>('board-game-room/quit', {
      token,
      room_id,
    }).then(output => {
      if (output.error) {
        ionAlert[0]({
          header: 'Failed to quit room',
          message: output.error,
          buttons: [{ text: 'Dismiss', role: 'cancel' }],
        })
        return
      }
      ionToast[0]({
        message: 'Quitted room',
        duration: ToastDuration.Short,
      })
      waitingRoom.reload()
    })
  }
  function startGame(room_id: number) {
    postAPI<typeof PostBoardGameRoomStart>('board-game-room/start', {
      token,
      room_id,
    }).then(output => {
      if (output.error) {
        ionAlert[0]({
          header: 'Failed to start game room',
          message: output.error,
          buttons: [{ text: 'Dismiss', role: 'cancel' }],
        })
        return
      }
      ionToast[0]({
        message: 'Started game',
        duration: ToastDuration.Short,
      })
      router.push(routes.board_game.chess_room)
    })
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle
            className={
              (isIPhone ? 'ion-no-padding' : '') + ' ' + styles.pageTitle
            }
          >
            Sweet Adventure: Waiting Room
          </IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={reload}>
              <IonIcon icon={refresh}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonModal isOpen={state.value.showRules}>
          <IonHeader>
            <IonToolbar color="primary">
              <PageTitle>Guidelines</PageTitle>
              <IonButtons slot="end">
                <IonButton onClick={closeRules}>Close</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <AboutLevel1 />
            <p style={{ margin: '5em' }}></p>
          </IonContent>
        </IonModal>

        <Tips text="Before starting the game, please read the rules and guide below." />
        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <IonButtons>
            <IonButton
              onClick={() => state.set('showRules', true)}
              color="tertiary"
            >
              <IonIcon icon={helpCircle}></IonIcon>
              Guidelines
            </IonButton>
          </IonButtons>
        </div>

        <IonLabel
          className="ion-padding-start"
          color="primary"
          style={{ opacity: state.value.room_name ? 1 : 0 }}
        >
          Room Name
        </IonLabel>
        <IonSearchbar
          className="ion-padding-horizontal"
          placeholder="Enter room name"
          value={state.value.room_name}
          onIonChange={e => state.set('room_name', e.detail.value || '')}
        ></IonSearchbar>

        <div className="ion-text-end ion-padding-horizontal">
          <IonButton
            size="small"
            fill="outline"
            onClick={() => createRoom()}
            disabled={!canCreateRoom()}
          >
            Create New Room
          </IonButton>
          <div
            hidden={
              !!state.value.room_name ||
              !!waitingRoom.getValue()?.current_room_id
            }
          >
            <IonText color="danger">(Input room name above to create)</IonText>
          </div>
        </div>

        <IonList className="ion-padding-horizontal">
          <IonListHeader>Board Game Room List</IonListHeader>
          {waitingRoom.render({
            name: 'Game Rooms',
            render: data => {
              const { user_id, current_room_id: inProgressRoomId } = data
              const joinedRoom = data.room_list.find(room =>
                room.user_list.find(user => user.id === user_id),
              )
              if (inProgressRoomId) {
                return (
                  <IonCard className="ion-text-center" color="secondary">
                    <p>The game is started 👾</p>
                    <IonButton
                      routerLink={routes.board_game.chess_room}
                      className="ion-margin-bottom"
                    >
                      enter room #{inProgressRoomId}
                    </IonButton>
                  </IonCard>
                )
              }
              return (
                <>
                  {data.room_list.length === 0 ? (
                    <IonCard>
                      <IonCardContent>
                        <IonNote color="dark">
                          ;'( No Active Game Rooms at the moment )';
                        </IonNote>
                        <br />
                        <IonNote color="primary">Let's create one!</IonNote>
                      </IonCardContent>
                    </IonCard>
                  ) : (
                    data.room_list.map(({ room, user_list }) => {
                      const isInRoom = !!user_list.find(
                        user => user.id === user_id,
                      )
                      const isRoomLeader =
                        room.leader_user_id === user_id &&
                        user_list.some(user => user.id === user_id)
                      const numberOfPlayer = user_list.length
                      const isRoomFull = numberOfPlayer >= 4
                      return (
                        <IonCard key={room.id}>
                          <IonCardHeader>
                            <IonRow>
                              <IonCol>
                                <IonText color="primary">
                                  #{room.id} - {room.name}
                                </IonText>
                              </IonCol>
                              <IonCol size="auto">
                                <IonButton
                                  size="small"
                                  color="primary"
                                  onClick={() => joinRoom(room.id)}
                                  hidden={
                                    !!inProgressRoomId ||
                                    !!joinedRoom ||
                                    isRoomFull
                                  }
                                >
                                  Join
                                </IonButton>
                                <IonButton
                                  size="small"
                                  color="dark"
                                  onClick={() => leaveRoom(room.id)}
                                  hidden={!isInRoom}
                                >
                                  Quit
                                </IonButton>
                                <IonButton
                                  size="small"
                                  color="success"
                                  onClick={() => startGame(room.id)}
                                  hidden={!isRoomLeader}
                                >
                                  Start
                                </IonButton>
                              </IonCol>
                            </IonRow>
                          </IonCardHeader>
                          <IonCardContent>
                            <div className={styles.roomList}>
                              {user_list.map(player => {
                                const nickname = toPlayerNickname(player)
                                return (
                                  <div
                                    key={player.id}
                                    className={styles.roomMember}
                                  >
                                    <IonAvatar>
                                      <img
                                        src={toAvatarUrl(
                                          player.profile_picture,
                                          player.nickname || player.id,
                                        )}
                                        alt={'avatar of ' + nickname}
                                      />
                                    </IonAvatar>
                                    {player.id === user_id ? (
                                      <IonText color="primary">
                                        <b>{nickname}</b>
                                      </IonText>
                                    ) : (
                                      <IonText color="dark">{nickname}</IonText>
                                    )}
                                  </div>
                                )
                              })}
                            </div>
                          </IonCardContent>
                        </IonCard>
                      )
                    })
                  )}
                </>
              )
            },
          })}
        </IonList>
      </IonContent>
    </IonPage>
  )
})
