import {
  IonMenu,
  IonContent,
  IonList,
  IonNote,
  IonMenuToggle,
  IonItem,
  IonIcon,
  IonLabel,
  IonHeader,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import {
  mailOutline,
  mailSharp,
  paperPlaneOutline,
  paperPlaneSharp,
} from 'ionicons/icons'
import { useLocation } from 'react-router-dom'
import { routes } from '../global/routes'

interface Page {
  url: string
  iosIcon: string
  mdIcon: string
  title: string
}

const pages: Page[] = [
  {
    title: 'Question Bank',
    url: '/admin/question',
    iosIcon: mailOutline,
    mdIcon: mailSharp,
  },
  {
    title: 'DM Feature',
    url: routes.admin.dm_feature,
    iosIcon: paperPlaneOutline,
    mdIcon: paperPlaneSharp,
  },
  {
    title: 'Analytics',
    url: '/admin/analytics',
    iosIcon: paperPlaneOutline,
    mdIcon: paperPlaneSharp,
  },
]

export const AdminMenu = () => {
  const location = useLocation()

  const email = 'hi@ionicframework.com'

  return (
    <IonMenu contentId="main" type="overlay">
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>Admin Portal</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonNote>Login as: {email}</IonNote>
        <IonList>
          {pages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem
                  className={
                    location.pathname === appPage.url ? 'selected' : ''
                  }
                  routerLink={appPage.url}
                  routerDirection="none"
                  lines="none"
                  detail={false}
                >
                  <IonIcon
                    slot="start"
                    ios={appPage.iosIcon}
                    md={appPage.mdIcon}
                  />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            )
          })}
        </IonList>
      </IonContent>
    </IonMenu>
  )
}
