import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonContent,
  IonButtons,
  IonAvatar,
  IonButton,
  IonCol,
  IonRow,
  IonText,
  IonCardHeader,
  IonCardContent,
  IonIcon,
  IonBadge,
  IonFab,
  IonFabButton,
  useIonAlert,
  IonModal,
  useIonRouter,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSearchbar,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
} from '@ionic/react'
import {
  Anonymous,
  callAPI,
  postAPI,
  toAvatarUrl,
  toImageUrl,
} from '../global/api'
import styles from './PhotoFeedPage.module.scss'
import { IonCard } from '@ionic/react'
import { addOutline, helpCircle, refresh, trash } from 'ionicons/icons'
import { formatTimestamp } from '../global/format'
import { routes } from '../global/routes'
import { PageTitle } from '../components/PageTitle'
import { GetPhotoFeed, PostFoodMenuCancel, PostMenuRating } from 'web-server'
import { useGetAutoRefresh } from '../hooks/use-get'
import { wrapToken } from '../components/wrap-token'
import { Rating } from '../components/Rating'
import { Tips } from '../components/Tips'
import { useObject } from '../hooks/use-object'
import useStorageState from 'react-use-storage-state'
import { AboutLevel3 } from '../components/AboutLevel3'
import { useEffect } from 'react'
import { ErrorMessage } from '../components/ErrorMessage'
import { config } from '../config'
import { HOUR } from '@beenotung/tslib/time'
import { table } from 'console'

const batchSize = 10

function toFilterDate(value: string): string {
  if (!value) {
    return ''
  }
  let date = new Date(value)
  date.setHours(0, 0, 0, 0)
  return date.toISOString()
}

export const PhotoFeedPage = wrapToken(({ token }) => {
  const [presentAlert] = useIonAlert()
  const router = useIonRouter()

  const [have_seen_level_3_help, set_have_seen_level_3_help] = useStorageState(
    'have_seen_level_3_help',
    false,
  )
  const [have_seen_level_3_finish, set_have_seen_level_3_finish] =
    useStorageState('have_seen_level_3_finish', false)

  type Menu = typeof GetPhotoFeed['output']['menu_list'][number]

  const state = useObject({
    showRules: !have_seen_level_3_help,
    offset: 0,
    menus: {} as Record<number, Menu>,
    infiniteScroll: null as null | HTMLIonInfiniteScrollElement,
    loadPhotoFeedError: '',
    filterDate: '',
    filterMealId: 0,
    filterNickname: '',
  })

  const photoFeed = useGetAutoRefresh<typeof GetPhotoFeed>('photo-feed', {
    token,
    offset: 0,
    count: batchSize,
    first_id: 0,
    date: toFilterDate(state.value.filterDate),
    meal_id: +state.value.filterMealId || 0,
    nickname: state.value.filterNickname || '',
  })
  const user_id = photoFeed.getValue()?.user.id
  const _menu_list = photoFeed.getValue()?.menu_list
  const meal_list = photoFeed.getValue()?.meal_list || []
  useEffect(() => {
    if (!_menu_list) return
    let menus = state.value.menus
    for (let menu of _menu_list) {
      menus = { ...menus, [menu.id]: menu }
    }
    state.patch({ menus })
  }, [_menu_list])

  function reloadMenu() {
    state.patch({ menus: {}, offset: 0 })
    if (state.value.offset === 0) {
      photoFeed.reload()
    }
  }
  async function loadMoreMenu() {
    let offset = state.value.offset + batchSize
    let first_id =
      Object.values(state.value.menus).sort((a, b) => b.id - a.id)[0]?.id || 0
    let json = await callAPI<typeof GetPhotoFeed>('get', 'photo-feed', {
      offset,
      token,
      count: batchSize,
      first_id,
      date: toFilterDate(state.value.filterDate),
      meal_id: +state.value.filterMealId || 0,
      nickname: state.value.filterNickname || '',
    })
    state.patch({ loadPhotoFeedError: json.error })
    if (json.error) {
      return
    }
    let menus = state.value.menus
    for (let menu of json.menu_list) {
      menus = { ...menus, [menu.id]: menu }
    }
    photoFeed.setState({ status: 'ready', value: json })
    state.patch({ offset, menus, loadPhotoFeedError: '' })
    state.value.infiniteScroll?.complete()
  }

  function closeRules() {
    state.set('showRules', false)
    set_have_seen_level_3_help(true)
  }

  function postRating(
    feed: typeof GetPhotoFeed['output']['menu_list'][number],
    rating: number,
  ) {
    postAPI<typeof PostMenuRating>('menu-rating', {
      token,
      rating,
      menu_id: feed.id,
    }).then(output => {
      if (output.error) {
        return
      }
      feed.my_rating = rating
      feed.total_star = output.total_star
      feed.stars = output.stars
      photoFeed.rerender()
      if (
        output.is_finish_menu &&
        (!have_seen_level_3_finish || config.showFinishLevelEveryTime)
      ) {
        set_have_seen_level_3_finish(true)
        router.push(routes.photo_feed.finish_level3)
      }
    })
  }

  function cancelMenu(menu_id: number) {
    presentAlert({
      header: 'Confirm to revoke DM Diet?',
      message:
        'This action is irreversible and the received rating will be lose.',
      buttons: [
        { text: 'Cancel', role: 'cancel' },
        {
          text: 'Revoke Diet',
          handler: () => {
            postAPI<typeof PostFoodMenuCancel>('food-menu-cancel', {
              token,
              menu_id,
            }).then(output => {
              if (output.error) {
                presentAlert({
                  header: 'Failed to revoke Food Diet',
                  message: output.error,
                  buttons: ['Dismiss'],
                })
                return
              }
              reloadMenu()
            })
          },
        },
      ],
    })
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <PageTitle>Featured DM Diet</PageTitle>
          <IonButtons slot="end">
            <IonButton onClick={photoFeed.reload}>
              <IonIcon icon={refresh}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonModal isOpen={state.value.showRules}>
          <IonHeader>
            <IonToolbar color="primary">
              <PageTitle>Guidelines</PageTitle>
              <IonButtons slot="end">
                <IonButton onClick={closeRules}>Close</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <AboutLevel3 />
            <p style={{ margin: '5em' }}></p>
          </IonContent>
        </IonModal>

        <Tips text="Before starting the game, please read the rules and guide below." />
        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <IonButtons>
            <IonButton
              onClick={() => state.set('showRules', true)}
              color="tertiary"
            >
              <IonIcon icon={helpCircle}></IonIcon>
              Guidelines
            </IonButton>
          </IonButtons>
        </div>

        {photoFeed.render({
          name: 'Photo Feed',
          render: page => (
            <div className="ion-padding-horizontal">
              <IonRow className="ion-text-center">
                <IonCol size="auto">
                  <IonAvatar className={styles.avatar}>
                    <img
                      loading="lazy"
                      src={toAvatarUrl(
                        page.user.image_url,
                        page.user.nickname || page.user.id,
                      )}
                    ></img>
                  </IonAvatar>
                  <div className="mt-2">
                    <IonText color="primary">
                      <b>{page.user.nickname}</b>
                    </IonText>
                  </div>
                </IonCol>
                <IonCol className="d-flex flex-column ion-align-items-center ion-justify-content-center">
                  <div className="d-flex w-100 ion-justify-content-around ion-align-items-center">
                    <div className="div">
                      <IonText color="primary">
                        <b>
                          {page.user.posts}
                          <br />
                          Posts
                        </b>
                      </IonText>
                    </div>
                    <div className="div">
                      <IonText color="primary">
                        <b>
                          {page.user.stars}
                          <br />
                          Stars
                        </b>
                      </IonText>
                    </div>
                  </div>
                  <div className="d-flex mt-3">
                    <IonButton
                      fill="outline"
                      size="small"
                      routerLink={routes.photo_feed.upload}
                    >
                      Upload
                    </IonButton>
                  </div>
                </IonCol>
              </IonRow>

              <h2>Featured Diabetes Management Diet</h2>

              <IonList>
                <IonListHeader>Search or filtering</IonListHeader>
                <IonItem>
                  <IonLabel>Date</IonLabel>
                  <IonInput
                    type="date"
                    value={state.value.filterDate}
                    onIonChange={e =>
                      state.patch({ filterDate: e.detail.value || '' })
                    }
                  />
                </IonItem>
                <IonItem>
                  <IonLabel>Meal</IonLabel>
                  <IonSelect
                    placeholder="meal"
                    value={state.value.filterMealId}
                    onIonChange={e =>
                      state.patch({ filterMealId: e.detail.value })
                    }
                  >
                    <IonSelectOption value={0}>All</IonSelectOption>
                    {meal_list.map(meal => (
                      <IonSelectOption value={meal.id} key={meal.id}>
                        {meal.meal}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
                <IonSearchbar
                  placeholder="nickname"
                  value={state.value.filterNickname}
                  onIonChange={e =>
                    state.patch({ filterNickname: e.detail.value || '' })
                  }
                />
              </IonList>

              {Object.values(state.value.menus)
                .filter(menu => {
                  if (
                    state.value.filterNickname &&
                    !menu.nickname?.includes(state.value.filterNickname)
                  ) {
                    return false
                  }
                  if (
                    state.value.filterMealId &&
                    +menu.meal_id !== +state.value.filterMealId
                  ) {
                    return false
                  }
                  if (state.value.filterDate) {
                    let from = new Date(
                      toFilterDate(state.value.filterDate),
                    ).getTime()
                    let to = from + 24 * HOUR
                    let date = new Date(menu.created_at).getTime()
                    return from <= date && date <= to
                  }
                  return true
                })
                .sort((a, b) => b.id - a.id)
                .map(feed => (
                  <IonCard key={feed.id}>
                    <IonCardHeader className="d-flex ion-align-items-center p-2">
                      <IonAvatar>
                        <img
                          loading="lazy"
                          src={toAvatarUrl(
                            feed.avatar,
                            feed.nickname || feed.user_id,
                          )}
                        />
                      </IonAvatar>
                      <IonText color="primary" className="ion-margin-start">
                        <b>{feed.nickname || Anonymous}</b>
                      </IonText>
                      <div className="flex-grow"></div>
                      <IonButtons>
                        <IonButton
                          color="dark"
                          onClick={() => cancelMenu(feed.id)}
                          hidden={feed.user_id != user_id}
                        >
                          <IonIcon icon={trash}></IonIcon>
                        </IonButton>
                      </IonButtons>
                    </IonCardHeader>
                    <IonCardContent className="ion-no-padding">
                      <div className="ion-text-center">
                        <img
                          loading="lazy"
                          src={toImageUrl(feed.cover_image)}
                        />
                      </div>
                      <p className="ion-padding-horizontal">
                        Meal: {feed.meal}
                      </p>
                      <p
                        className="ion-padding-horizontal"
                        hidden={feed.feature_list.length == 0}
                      >
                        Feature: {feed.feature_list.join(', ')}
                      </p>
                      <p
                        className="ion-padding-horizontal"
                        hidden={!feed.description}
                      >
                        Description: {feed.description}
                      </p>
                      <Rating
                        value={feed.my_rating}
                        onChange={rating => postRating(feed, rating)}
                      />
                      <p className="ion-padding-horizontal">
                        Total stars: {feed.total_star}
                      </p>
                      <table
                        className={styles.starTable + ' ion-margin-horizontal'}
                      >
                        <tbody>
                          <tr>
                            <td>5 star</td>
                            <td>{feed.stars[5]} votes</td>
                          </tr>
                          <tr>
                            <td>4 star</td>
                            <td>{feed.stars[4]} votes</td>
                          </tr>
                          <tr>
                            <td>3 star</td>
                            <td>{feed.stars[3]} votes</td>
                          </tr>
                          <tr>
                            <td>2 star</td>
                            <td>{feed.stars[2]} votes</td>
                          </tr>
                          <tr>
                            <td>1 star</td>
                            <td>{feed.stars[1]} votes</td>
                          </tr>
                        </tbody>
                      </table>
                      <p className="ion-padding-horizontal">
                        Post Time: {formatTimestamp(feed.created_at)}
                      </p>
                      {feed.tag_list.map(tag => (
                        <IonBadge key={tag} className="ms-2">
                          #{tag}
                        </IonBadge>
                      ))}
                    </IonCardContent>
                  </IonCard>
                ))}

              <ErrorMessage error={state.value.loadPhotoFeedError} />

              <IonInfiniteScroll
                ref={e =>
                  state.value.infiniteScroll !== e &&
                  state.patch({ infiniteScroll: e })
                }
                onIonInfinite={e => loadMoreMenu()}
              >
                <IonInfiniteScrollContent
                  loadingSpinner="bubbles"
                  loadingText="Loading more menu..."
                ></IonInfiniteScrollContent>
              </IonInfiniteScroll>
            </div>
          ),
        })}

        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton routerLink={routes.photo_feed.upload}>
            <IonIcon icon={addOutline}></IonIcon>
          </IonFabButton>
        </IonFab>
      </IonContent>
    </IonPage>
  )
})
