import { useCallback, useRef, useState } from 'react'

export function useS<T>(initial: T) {
  const ref = useRef(initial)
  const [_, setState] = useState(0)
  function s(value?: T) {
    if (arguments.length === 1) {
      ref.current = value!
      setState(state => state + 1)
    }
    return ref.current
  }
  return useCallback(s, [])
}
