import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
  IonBackButton,
  IonListHeader,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonIcon,
  IonModal,
  useIonLoading,
  useIonAlert,
} from '@ionic/react'
import { routes } from '../global/routes'
import { useGet } from '../hooks/use-get'
import type {
  GetAllAnalysisData,
  GetAnalysisData,
  GetAnalysisDataList,
  PostDBReset,
} from 'web-server'
import useStorageState from 'react-use-storage-state'
import { downloadOutline } from 'ionicons/icons'
import { callAPI, SERVER_ORIGIN } from '../global/api'
import { useObject } from '../hooks/use-object'
import {
  csv_to_json,
  from_csv,
  json_to_csv,
  to_csv,
} from '@beenotung/tslib/csv'
import { fileToText, saveStringToFile, selectFile } from '@beenotung/tslib/file'

export const AdminHomePage = () => {
  const [password, setPassword] = useStorageState('admin_password', '')
  const dataList = useGet<typeof GetAnalysisDataList>('analysis-data-list', {
    password,
  })
  const state = useObject({
    error: '',
    name: '',
    headers: [] as string[],
  })
  const [presentLoading, dismissLoading] = useIonLoading()
  const [presentAlert, dismissAlert] = useIonAlert()
  function resetDB() {
    presentAlert(
      'Do you confirm to reset all data? This action is irreversible.',
      [
        { role: 'cancel', text: 'Cancel' },
        {
          role: 'destructive',
          text: 'Reset All',
          handler: () => {
            presentLoading('Resetting all data ...')
            callAPI<typeof PostDBReset>('post', 'db-reset', {
              password,
            })
              .then(data => {
                state.patch({ error: '' })
              })
              .catch(error => {
                state.patch({ error })
              })
              .finally(dismissLoading)
          },
        },
      ],
    )
  }
  function downloadAll() {
    presentLoading('Exporting all analysis data ...')
    callAPI<typeof GetAllAnalysisData>('get', 'all-analysis-data', { password })
      .then(data => {
        state.patch({ error: '' })
        let params = new URLSearchParams()
        params.set('password', password)
        let url = SERVER_ORIGIN + '/' + data.url + '?' + params
        // console.log(url)
        let a = document.createElement('a')
        a.download = data.url.split('/').pop()!
        // a.target = '_self'
        a.href = url
        a.click()
      })
      .catch(error => {
        state.patch({ error })
      })
      .finally(dismissLoading)
  }
  function download(name: string) {
    callAPI<typeof GetAnalysisData>('get', 'analysis-data', { password, name })
      .then(data => {
        state.patch({ error: '', name })
        let rows = json_to_csv(data.rows)
        if (rows.length == 0) {
          return
        }
        state.set('headers', rows[0])

        let csv = to_csv(rows)
        let filename = name + '.csv'
        saveStringToFile(csv, 'text/csv', filename)
      })
      .catch(error => {
        state.patch({ error, headers: [], name })
      })
  }
  async function downloadEmailListTemplate() {
    let rows = [['email'], ['alice@example.com'], ['bob@example.com']]
    let csv = to_csv(rows)
    let filename = 'user-list.csv'
    saveStringToFile(csv, 'text/csv', filename)
  }
  async function hashEmailList() {
    let [file] = await selectFile({
      accept: '*.csv',
    })
    if (!file) return
    let csv = await fileToText(file)
    let rows = from_csv(csv)
    let users = csv_to_json(rows)
    for (let user of users) {
      debugger
      let sourceBytes = new TextEncoder().encode(user.email)
      let digest = await crypto.subtle.digest('SHA-256', sourceBytes)
      user.email_hash = Array.from(new Uint8Array(digest), x =>
        x.toString(16).padStart(2, '0'),
      ).join('')
    }
    csv = to_csv(json_to_csv(users))
    saveStringToFile(csv, 'text/csv', file.name)
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton defaultHref={routes.more} />
          </IonButtons>
          <IonTitle>Admin Portal</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonItem>
          <IonLabel position="floating" color="primary">
            Admin Password
          </IonLabel>
          <IonInput
            type="password"
            value={password}
            onIonChange={e => setPassword(e.detail.value || '')}
          ></IonInput>
        </IonItem>

        {/* error modal */}
        <IonModal isOpen={!!state.value.error}>
          <IonHeader>
            <IonToolbar color="primary">
              <IonButtons slot="end">
                <IonButton onClick={() => state.set('error', '')}>
                  Close
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <p>Failed to export data:</p>
            <p>{state.value.error}</p>
          </IonContent>
        </IonModal>

        {/* data modal */}
        <IonModal isOpen={state.value.headers.length > 0}>
          <IonHeader>
            <IonToolbar color="primary">
              <IonButtons slot="end">
                <IonButton onClick={() => state.set('headers', [])}>
                  Close
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <div>Data Sheet: {state.value.name}</div>
            <div>
              Headers:
              <ul>
                {state.value.headers.map(name => (
                  <li key={name}>{name}</li>
                ))}
              </ul>
            </div>
          </IonContent>
        </IonModal>

        {/* email list */}
        <IonList>
          <IonListHeader>Hash Email List</IonListHeader>

          <div className="ion-text-end ion-margin-bottom">
            <IonButton
              size="small"
              color="dark"
              onClick={() => downloadEmailListTemplate()}
            >
              Download Template
            </IonButton>
            <IonButton size="small" onClick={() => hashEmailList()}>
              Process
            </IonButton>
          </div>
        </IonList>

        {/* data list */}
        <IonList>
          <IonListHeader>Analysis Data List</IonListHeader>

          {dataList.render({
            name: 'Analysis Data List',
            render: data => (
              <>
                <div className="ion-text-end ion-margin-bottom">
                  <IonButton
                    size="small"
                    color="dark"
                    onClick={() => resetDB()}
                  >
                    Reset Database
                  </IonButton>
                  <IonButton size="small" onClick={() => downloadAll()}>
                    Download all
                  </IonButton>
                </div>
                {data.name_list.map(name => (
                  <IonItem key={name}>
                    <IonLabel className="ion-text-wrap">{name}</IonLabel>
                    <IonButton
                      slot="end"
                      size="small"
                      onClick={() => download(name)}
                    >
                      <IonIcon icon={downloadOutline}></IonIcon>
                    </IonButton>
                  </IonItem>
                ))}
              </>
            ),
          })}
        </IonList>
      </IonContent>
    </IonPage>
  )
}
