import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemGroup,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonThumbnail,
  IonToolbar,
} from '@ionic/react'
import {
  cashOutline,
  pricetagsOutline,
  pricetagsSharp,
  pulseOutline,
} from 'ionicons/icons'
import { GetSportCart, PostSportCheckout } from 'web-server'
import { assets } from '../assets'
import { ErrorMessage } from '../components/ErrorMessage'
import { wrapToken } from '../components/wrap-token'
import { PageTitle } from '../components/PageTitle'
import { postAPI, toImageUrl } from '../global/api'
import { routes } from '../global/routes'
import { useGet } from '../hooks/use-get'
import { useObject } from '../hooks/use-object'
import styles from './ShoppingCartCheckoutPage.module.scss'
import ShopQuestionModal2 from './ShopQuestionModal2'
import { config } from '../config'
import { Tips } from '../components/Tips'

export const SportCenterCheckoutPage = wrapToken(({ token }) => {
  const sportCart = useGet<typeof GetSportCart>('sport-cart', {
    token,
  })
  const state = useObject({
    submit_error: '',
    cart_error: '',
    sport_batch_id: 0,
    is_finish_shop: false,
  })
  const { sport_batch_id } = state.value

  const checkout = (sport_cart_id_list: number[]) => {
    postAPI<typeof PostSportCheckout>('sport-checkout', {
      token,
      sport_cart_id_list,
    }).then(output => {
      state.set('submit_error', output.error)
      state.set('sport_batch_id', output.sport_batch_id)
      state.set('is_finish_shop', output.is_finish_shop)
    })
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton defaultHref={routes.shop.home} />
          </IonButtons>
          <PageTitle>Sport Center Checkout</PageTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <Tips text="After checkout, benchmark your chosen sports against the exercise principles for diabetes." />

        <img
          src={assets.payment_sport}
          alt="cashier illustration"
          className={styles.cashierBanner + ' ion-margin-top'}
        />
        {sportCart.render({
          name: 'Sport Cart',
          render: data => {
            const item_list = data.sport_list
            let total_price = data.SportBatchFee
            let total_bonus = 0
            item_list.forEach(item => {
              total_bonus += item.reward
            })
            let remind_coins = data.user.coins - total_price + total_bonus
            let sport_cart_id_list = item_list.map(item => item.sport_cart_id)
            return (
              <>
                <IonList className="ion-padding-horizontal">
                  <IonListHeader>Sport Items</IonListHeader>
                  {item_list.length === 0 ? (
                    <div className="ion-text-center ion-margin">
                      <ErrorMessage error="No Items in Cart" />
                    </div>
                  ) : null}
                  {item_list.map(item => (
                    <IonItemGroup
                      className="ion-margin-bottom"
                      key={item.sport_cart_id}
                    >
                      <IonItem>
                        <IonThumbnail slot="start">
                          <img
                            src={toImageUrl(item.image_url)}
                            alt={'illustration of ' + item.name}
                          />
                        </IonThumbnail>
                        <IonLabel className="ion-text-wrap">
                          <b>{item.name}</b>
                          <div>{item.time_per_week} times per week</div>
                          <div>{item.hour_per_exercise} hours per exercise</div>
                        </IonLabel>
                      </IonItem>
                      <div className="ion-text-end">
                        <span
                          className="ion-padding-start"
                          hidden={config.hideSportRewardHint}
                        >
                          <IonIcon
                            color="success"
                            icon={pricetagsSharp}
                          ></IonIcon>{' '}
                          earn {item.reward} coins
                        </span>
                      </div>
                    </IonItemGroup>
                  ))}
                  <IonListHeader>Purchase Total</IonListHeader>
                  <IonItem>
                    <IonIcon
                      color="warning"
                      icon={cashOutline}
                      slot="start"
                    ></IonIcon>
                    <IonLabel>cost {total_price} coins</IonLabel>
                  </IonItem>
                  <IonItem hidden={config.hideHealthyFoodHint}>
                    <IonIcon
                      color="success"
                      icon={pricetagsOutline}
                      slot="start"
                    ></IonIcon>
                    <IonLabel>earn {total_bonus} coins</IonLabel>
                  </IonItem>
                  <IonListHeader>After Purchase</IonListHeader>
                  <IonItem>
                    <IonIcon
                      color="warning"
                      icon={cashOutline}
                      slot="start"
                    ></IonIcon>
                    <IonLabel>remain {remind_coins} coins</IonLabel>
                  </IonItem>
                  <IonItem hidden>
                    <IonIcon
                      color="success"
                      icon={pulseOutline}
                      slot="start"
                    ></IonIcon>
                    saved {total_bonus} coins
                  </IonItem>
                </IonList>
                <ErrorMessage
                  error={
                    remind_coins < 0
                      ? `Not enough coins, need ${-remind_coins} more coins`
                      : state.value.cart_error
                  }
                />
                <IonButton
                  className="ion-margin"
                  expand="block"
                  onClick={() => checkout(sport_cart_id_list)}
                  disabled={item_list.length === 0 || remind_coins < 0}
                >
                  Checkout
                </IonButton>
                <ErrorMessage error={state.value.submit_error} />
              </>
            )
          },
        })}
        {sport_batch_id ? (
          <ShopQuestionModal2
            key={sport_batch_id}
            is_finish_shop={state.value.is_finish_shop}
            token={token}
            purchase_id={undefined}
            sport_batch_id={sport_batch_id}
          />
        ) : null}
      </IonContent>
    </IonPage>
  )
})
